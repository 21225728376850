import * as React from 'react';

import Markdown from 'react-markdown';
import {PluggableList} from 'react-markdown/lib';
import rehype from 'rehype-raw';

export default function CustomMarkdownRenderer(props: {
  className: string,
  remarkPlugins: PluggableList
  children: string
}) {

  return (
    <Markdown className={props.className} remarkPlugins={props.remarkPlugins} rehypePlugins={[rehype]}>
      {props.children}
    </Markdown>
  )
}
