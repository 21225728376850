import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CCC = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const id = Math.floor(100000 + Math.random() * 900000).toString();
  const keyString = uuid().slice(0, 12);

  const handleCreate = async (id: string, keyString: string) => {
    try {
      await axios
        .get(`${baseURL}api/find-qnr`, { params: { qnrId: id } })
        .then(() => {
          id = Math.floor(100000 + Math.random() * 900000).toString();
          handleCreate(id, keyString);
        });
    } catch (e) {
      let goLogin = false;

      await axios
        .post(`${baseURL}api/create-qnr`, {
          customId: id,
          keyStr: keyString,
        })
        .catch((err: authenticationError) => {
          if (err.response.status === 401) {
            goLogin = true;
            localStorage.setItem('afterLogInLocation', baseURL+"ccc");
            navigate('/login');
          }
        });
      if (!goLogin) {
        let logged = true;
        axios
          .put(`${baseURL}api/set-qnr-type`, {
            params: {
              qnrId: id,
              qnrStr: keyString,
              qnrName: 2,
            },
          })
          .catch((e: authenticationError) => {
            if (e.response.status === 401) {
              logged = false;
              localStorage.setItem('afterLogInLocation', baseURL+"ccc");
              navigate('/login');
            }
          })
          .then(() => {
            if (logged) {
              navigate(`/create/${id}`, { state: keyString });
            }
          });
      }
    }
  };

  useEffect(() => {
    handleCreate(id, keyString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <h1>Loading...</h1>;
};

export default CCC;
