import { useTranslation } from 'react-i18next'
import axios from 'axios'
import * as React from 'react'
import style from './ConfirmDelete.module.css';


export default function ConfirmDeletePopup(props: {
  qnrId: string,
  setPopUpQnrId: React.Dispatch<React.SetStateAction<string>>,
}){

  const baseUrl = process.env.REACT_APP_BASE_URL
  const deleteQnr = () => {
    axios
    .post(`${baseUrl}api/delete-qnr`, 
      {
        qnrId: props.qnrId
      }
    ).then((result) => {
      window.location.reload()
    })
    
  }


  const cancelDeletion = () => {
    console.log('hello vro wuw');
    props.setPopUpQnrId('');
  }

  
  const { t } = useTranslation()

  return (
    <>
      <section 
        className = {`${style.confirmDeleteContent} ${style.ignoreClose}`}
      >
        <span
          className= {`${style.confirmDeleteText} ${style.ignoreClose}`} >
          {t('Are you sure you want to delete this questionnaire?')}
        </span>

        <section
          className={`${style.confirmDeleteBtnCnt} ${style.ignoreClose}`}
        >
          <button
            title='confirm'
            className={`${style.popupBtn} ${style.confirmDeleteBtn} ${style.ignoreClose}`}
            onClick={deleteQnr}
          >
            {t('Confirm')}
          </button>

          <button
            title='cancel'
            className={`${style.popupBtn} ${style.cancelDeleteBtn} ${style.ignoreClose}`}
            onClick={cancelDeletion} >
            {t('Cancel')}
          </button>
        </section>
        
      </section>
    </>

  )
}
