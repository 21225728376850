import * as React from 'react';
import UserAgreement from './UserAgreementLink';
import { useEffect } from 'react';

export default function TeamsUserAgreement() {
  
  const linksClassName = "user-agreement-link"

  function changeRedirects(){
    
    const links = document.getElementsByClassName(linksClassName) as HTMLCollectionOf<HTMLAnchorElement>

    for(let i = 0; i < links.length; i ++){
      links[i].target = '_blank'
    }

  }

  useEffect(changeRedirects, [])
  
  return (
    <UserAgreement />
  )
}