import Navbar from '../Navbar'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Dots from '../components/Dots'
import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function DisableNotifications(){

  const { t } = useTranslation()

  const [outputText, setOutputText] = useState('Disabling notifications')

  const baseUrl = process.env.REACT_APP_BASE_URL
  const nav = useNavigate() 
  const { userCode } = useParams()

  const callDisableNotifications = () => {

    const url = `${baseUrl}api/disable-notifications`
    const body = {
      userCode : userCode
    }

    axios
    .post(url, body)
    .then((result) => {
      const resultSuccess = [200, 201].includes(result.status)
      if(resultSuccess){
        setOutputText('Notifications successfully disabled')
      }else{
        setOutputText('Error with disabling notifications')
      }
      setInterval(() => nav('/'), 3000)
    })
  }

  useEffect( () => {
    callDisableNotifications()
  })

  return(
    <>
      <Navbar/>

      <h1>

        {t(outputText)}{outputText === 'Disabling notifications' ? <Dots /> : ''} 
        <br/>
        <span
          style = {{
            fontSize : '1.5rem'
          }}
        >
          {t(outputText !== 'Disabling notifications' ? `You will be redirected shortly` : '')}{outputText !== 'Disabling notifications' ? <Dots /> : ''}
        </span>
      </h1>
    </>
  )
}
