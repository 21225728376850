import UserAgreementLink from '../../components/UserAgreementLink'; import Navbar from '../../Navbar';
import * as React from 'react';
import { useState } from 'react';
import { getUserSelectedLanguage } from '../../functions/getUserLanguage';
import axios from 'axios';
import style from './Login.module.css';
import {useTranslation} from 'react-i18next';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputOtp } from 'primereact/inputotp';
import { Checkbox } from 'primereact/checkbox';

import { OTP_LENGTH } from '../../constants';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import './login.css';

export default function Login() {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [saveLogin, setSaveLogin] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(false);

  const toggleSaveLogin = () => {
    setSaveLogin((curr) => {
      localStorage.setItem('keepLoggedIn', !curr + '')
      return !curr;
    })
  }; 
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleOtpWrite = (otp: string | number | null | undefined) => {
    if(!otp) return; 

    setOtpError(false);
    setOtp(otp + '');
  }

  const onOtpSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if(otp.length !== OTP_LENGTH){
      setOtpError(true);
      return;
    }

    axios
    .get('/api/otp-login', { params: {
      keepLoggedIn: saveLogin, otp, email
    }})
    .then(() => {
      window.location.href='/';
    })
    .catch(() => {
      setOtpError(true);
    });
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsEmailSending(true);
    await axios
      .get('/api/signin', {
        params: { 
          email: email,
          language: getUserSelectedLanguage(),
          saveLogin
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          alert('Failed to send the email. Try again later.');
        }
        setIsEmailSent(true);
        setIsEmailSending(false);
      })
      .catch(() => {
        alert('Failed to send the email. Try again later.');
        console.log('error');
      });
  };

  const h2Message = t("We promise, it's Qck and easy!");

  return (
    <>
      <Navbar />
      <div className={style.container}>
        <h1 className={style.heading1}>{t('Sign in to your Qck account')}</h1>
        <h2 className={style.heading2}>{h2Message}</h2>
        {!isEmailSent && <form onSubmit={onSubmit} className={style.form}>
          <h3 className={style.heading3}>Enter your email</h3>
          <InputText
            placeholder='email'
            onChange={(e) => handleChange(e)}
            value={email}
            type='email'
            required
            className={style.emailInput}
          />
          {/* <br /> */}
          <div className={style.saveLoginContainer}>
            <Checkbox 
              className={style.stayLoggedInCheckbox}
              id='stay-logged-in'
              type='button'
              onClick={toggleSaveLogin}
              checked={saveLogin}
            />
            <label
              htmlFor='stay-logged-in'
              className={style.saveLoginLabel}
            >
              {t('Stay logged in')}
            </label>
          </div>
          <Button
            className={style.submitButton}
            type="submit"
          >SUBMIT</Button>
        </form>}
        {isEmailSent && <form className={style.form} onSubmit={onOtpSubmit}>
          <p className={style.emailP}>{t('Your email')}: {email}</p>
          <p className={style.clickLinkP}>Click the link from the email</p>
          <h3 className={style.headingOr}>OR</h3>
          <div className={style.otpContainer}>
            <label className={style.otpLabel}>Enter the code you recieved here</label>
            <InputOtp
              onChange={(e) => handleOtpWrite(e.value)}
              invalid={otpError}
              integerOnly
              length={OTP_LENGTH}
            /> 
          </div>
          <Button
            className={style.submitButton}
            type='submit'
          >SUBMIT</Button>
        </form>}

        {/*isEmailSent && (
          <>
            <h2>Now check your email</h2>
            <h2 style={{ color: 'gray', fontSize: '1.2rem' }}>
              If you have not received an email yet, try submitting again
            </h2>
          </>
          )*/}

        {isEmailSending && (
          <>
            <h2>Sending...</h2>
          </>
        )}

        <UserAgreementLink />
      </div>
    </>
  );
}
