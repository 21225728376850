import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/switchResultsPrivacy.css';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_BASE_URL;
export default function SwitchResultsPrivacy(props: {
  qnrId: string;
  qnrStr: string;
  shared: boolean;
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [shared, setShared] = useState(props.shared);
  const handleClick = async () => {
    setLoading(true);
    setTimeout(() => 5000);
    await axios
      .put(baseUrl + 'api/set-qnr-privacy', {
        params: {
          qnrId: props.qnrId,
          qnrStr: props.qnrStr,
          shared: !shared,
        },
      })
      .then(() => {
        setShared(!shared);
        setLoading(false);
      })
      .catch((e: authenticationError) => {
        if (e.response.status === 401) {
          setShared(!shared);
          localStorage.setItem('afterLogInLocation', window.location.href);
          navigate('/login');
        }
        setLoading(false);
      });
  };
  return (
    <div className="privacy-toggle-wrapper">
      <span className="privacy-checkbox-label">
        {t('Allow voters to see the results')}{' '}
      </span>
      <label className="switch">
        <input type="checkbox" checked={shared} onChange={handleClick} />
        <span className="slider"></span>
        {loading ? (
          <CircularProgress
            style={{ color: '#DADADA', padding: 3 }}
            size={28}
          />
        ) : (
          ''
        )}
      </label>
    </div>
  );
}
