import * as React from 'react';

import {Button} from '@mui/material';
import {ReactComponent as CopySvg} from '../../images/copy.svg';
import style from './CopyLinkButton.module.css';

export default function CopyLinkButton(props: {
  text: string
  link: string
}) {


  const [ doShowPopup, setDoShowPopup ] = React.useState<boolean>(false);
  
  let hidePopupTimeout: number | undefined;

  const handleClick = () => {

    if(hidePopupTimeout !== undefined){
      window.clearTimeout(hidePopupTimeout);
      hidePopupTimeout = undefined;
    }else{
      setDoShowPopup(true);
      hidePopupTimeout = window.setTimeout(() => setDoShowPopup(false), 3300);
    }

    navigator.clipboard.writeText(props.link);
  }

  return (
    <>
      <button className={`${style.button}`} onClick={handleClick}>
        <CopySvg />
      </button>
      <div className={`${style.copyPopup} ${doShowPopup ? style.show : style.hide}`}>
        {props.text}
      </div>
    </>
  )
}
