import * as React from "react";

import style from './Invitees.module.css';
import {useMeeting} from "../../../contexts/MeetingContext";
import { TextField } from "@mui/material";
import CopyLinkButton from "../../../components/Meeting/CopyLinkButton";
import {useParams} from "react-router-dom";
import axios from "axios";
import randomColor from "../../../functions/randomColor";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

export const statusColorMap: Record<InvitationStatus, string> = {
  'fail': 'red',
  'sending': 'orange',
  'sent': 'green',
  'accepted': 'blue',
}

export default function Invitees(props: {
  invitees: Invitee[],
  dateTimeEnded: number | undefined
  setInvitees: (_new: Invitee[]) => void
}) {
  const [emailText, setEmailText] = React.useState('');
  const [addEmailActive, setAddEmailActive] = React.useState(false);
  const [emailInputError, setEmailInputError] = React.useState<string | false>(false);

  const { id } = useParams();

  const [ invitees, setInvitees ] = React.useState(props.invitees);

  const { dateTimeEnded } = props;

  const [ inviteeRoles, setInviteeRoles ] = React.useState<string[]>(invitees.map(invitee => invitee.role));

  const [ changes, setChanges ] = React.useState<InviteeChange[]>([]);

  const syncIntervalId = React.useRef<number>();
  const isRequestActive = React.useRef<boolean>(false);

  const sync = () => setChanges(curr => {
    if(isRequestActive.current) return curr;

    console.log(curr);
    axios
    .post('/api/meeting-invitees', { id, changes: curr })
    .then((response) => {
      if(response.status === 200){
        setChanges(curr_after => {
          if(curr_after.length <= 0){
            const invitees = response.data as Invitee[];
            setInvitees(invitees);
            props.setInvitees(invitees);
            setInviteeRoles(invitees.map(invitee => invitee.role));
          }
          return curr_after;
        })
      }
      isRequestActive.current = false;
    })
    isRequestActive.current = true;
    
    return []
  })

  const addChange = (type: InviteeChangeType, props: any) => {
    setChanges(curr => [...curr, { type, props }]);
  }

  React.useEffect(() => {
    syncIntervalId.current = window.setInterval(sync, 1000);

    return () => window.clearInterval(syncIntervalId.current);
  }, []);

  //TODO
  const addInvitee = (email: string) => {

    const newInvitee: Invitee = {
      email,
      invitationStatus: 'sending',
      color: randomColor(),
      role: 'User'
    }

    setInvitees((curr) => {
      return [...curr, newInvitee]
    })
    setInviteeRoles([...inviteeRoles, newInvitee.role])
    
    addChange('new', { invitee: newInvitee });
    addChange('email', { email });
  }
  //TODO
  const removeInvitee = () => {
    return;
  }

  const emailInputRef = React.useRef<HTMLInputElement | null>(null);

  const inviteLink = `${process.env.REACT_APP_BASE_URL}p/${id}`

  const handleEmailChange = () => { 
    setAddEmailActive(!!emailText);
  }
  const handleEmailWrite = (event: React.ChangeEvent) => {
    setEmailInputError(false);
    setEmailText((event.target as HTMLInputElement).value);
  }
  
  const handleNewEmail = (event: React.FormEvent) => {
    event.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if(!emailRegex.test(emailText)){
      setEmailInputError('Invalid email');
      return;
    }
    if(emailText.length > 40){
      setEmailInputError('Email too long');
      return;
    }
    if(invitees.some((el) => el.email === emailText)){
      setEmailInputError('Email already in list');
      return;
    }
    setEmailText((curr) => {
      addInvitee(curr);
      return "";
    });
  }

  const changeUserRole = (ind:number,value:string) => {
    const arr = [...inviteeRoles]
    arr[ind] = value
    setInviteeRoles(arr);
    addChange('role', { email: invitees[ind].email, role: value });
  }

  React.useEffect(handleEmailChange, [emailText]);

  return (
    <>
      <section
        className={style.content}
      >
        <div
          style={{
            display: dateTimeEnded ? 'none': 'flex',
            flexDirection: 'column'
          }}
        >
          <h1 className={style.heading1}>To invite people</h1>
          <div className={style.shareLinkWrapper}>
            <div className={style.shareLinkContainer}>
              <span>
                Copy and share this link
              </span>
              <a href={inviteLink}>
                {inviteLink}
              </a>
            </div>
            <div className={style.shareLinkButtonWrapper}>
              <CopyLinkButton text={"Link copied succesfully"} link={inviteLink}/>
            </div>
          </div>
          <h1 className={style.heading2}> OR </h1>
        </div>
        <h1 className={style.heading} >
          {
            dateTimeEnded
              ? 'Here is a list of all the participants'
              : 'Enter the emails of everyone you want to participate'
          }
        </h1>
        { invitees.length > 0 ? 
        <ul className={style.emailsList} >
          {invitees.map((invitee, index) => (
            <li key={index} className={style.emailItem} >
              <div
                className={style.emailContainer}
              >
                <span className={style.emailText} >
                  {invitee.email}
                </span>

                <div>
                <Dropdown value={inviteeRoles[index]} onChange={(e)=>changeUserRole(index,e.value)} options={['User','Contributor','Co-owner']} checkmark={true} highlightOnSelect={false} placeholder={inviteeRoles[index]} disabled={inviteeRoles[index]=='Owner'}></Dropdown>
                <span className={style.emailStatus}
                  style={{
                    color: statusColorMap[invitee.invitationStatus]
                  }}
                >
                  {invitee.invitationStatus}
                </span>
                </div>
              </div>
              {/*
              <button
                className={style.deleteEmailButton}
                title="Delete email"
                onClick={() => removeInvitee(invitee.email)}
                style={{
                  display: dateTimeEnded ? 'none' : 'flex'
                }}
              >
                X
              </button>
              */}
            </li>
          ))
         }
        </ul> : 
        <h2
          style={{
            fontSize: '2.5rem',
            display: dateTimeEnded ? 'block' : 'none'
          }}
        >
          It looks like no one participated in your Qck
        </h2>}
        <form
          className={style.newEmailWrapper}
          onSubmit={handleNewEmail}
          style={{
            display: dateTimeEnded ? 'none' : 'flex'
          }}
        >
          <TextField
            ref={emailInputRef}
            value={emailText}
            className={style.newEmailInput}
            placeholder="Click to enter new email"
            onChange={handleEmailWrite}
            inputProps={{style: {fontSize: 20, lineHeight: 1.2}}}
            variant="standard"
            error={!!emailInputError}
            helperText={emailInputError || ''}
            FormHelperTextProps={{style: {fontSize: 12, lineHeight: 1.2}}}
          />
          <Button
            disabled={!addEmailActive}
            className={style.newEmailButton}
            type="submit"
          >
            Invite
          </Button>
        </form>
      </section>
    </>
  )
}
