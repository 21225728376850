import { useTranslation } from 'react-i18next';
import '../styles/userAgreement.css';
import * as React from 'react';

export default function UserAgreement() {
  const { t } = useTranslation();
  return (
    <div id="user-agreement-wrapper">
      <span id="user-agreement-content">
        {t('By using the services of this site, I agree to the')}{' '}
        <a
          id="user-agreement-link"
          className = 'user-agreement-link'
          target='_blank'
          href={`${process.env.REACT_APP_BASE_URL}user-agreement`}
          rel='noreferrer'
        >
          {t('End User License Agreement')}
        </a>
      </span>
      <span id="user-agreement-content">
        <a
          id="user-agreement-link"
          href={`${process.env.REACT_APP_BASE_URL}contact`}
          target='_blank'
          className = 'user-agreement-link'
          rel='noreferrer'
        >
          <br />
          {t('Contact us')}
        </a>
      </span>
    </div>
  );
}
