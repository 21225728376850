import * as React from 'react';
import axios from 'axios';
import style from './Meeting.module.css';
import {Button, TextareaAutosize} from '@mui/material';
import WorkingQuestionNav from '../../../components/Meeting/WorkingQuestionNav';

import {useMeeting} from '../../../contexts/MeetingContext';
import { TextField } from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import Dots from '../../../components/Dots';

export default function Meeting(props: {
  meeting: Meeting
}) {

  const [ meeting, setMeeting ] = React.useState(props.meeting);

  const [ requestForMeetingExists, setRequestForMeetingExists ] = React.useState<boolean>(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const startMeeting = () => {
    axios
    .put('/api/meeting-start', { id })
    .then(() => {
      window.location.href = `/${id}/start`;
    })
  }

  React.useEffect(() => {
    if(requestForMeetingExists) return;

    setRequestForMeetingExists(true);

    axios
    .get('/api/meeting', { params: { meetingId: id, pool: true } })
    .then((response) => {
      const meeting: Meeting = response.data.meeting;

      console.log('meeting', meeting);
      console.log('response', response);
      if(!meeting) return;
      setMeeting(meeting);
      setRequestForMeetingExists(false);
    })


  }, [requestForMeetingExists]);

  const intialRequestMade = React.useRef<boolean>(false);

  React.useEffect(() => {
    if(intialRequestMade.current.valueOf()) return;

    axios
    .get('/api/meeting', { params: { meetingId: id } })
    .then( (response) => {
      if(response.data.meeting)
        setMeeting(response.data.meeting);
    })
  }, [])
  
  const hasMeetingStarted = (): boolean => !!meeting.dateTimeStarted;

  return (
    <div
      className={style.content}
    >
      <h1
        className={style.heading1}
      >
        {hasMeetingStarted()
          ? 'Meeting overview'
          : 'Finalize your meeting' }
      </h1>
      <section
        className={style.overview}
      >
        <div
          className={style.workingQuestionsContainer}
        >
          <h2
            className={style.heading2}
          >
            Your working questions
          </h2>
          {meeting.workingQuestions.length > 0 && meeting.workingQuestions[0].text
            ? <>
                <ul
                  className={style.propsList}
                >
                  {meeting.workingQuestions.map((wq, index) => (
                    <li
                      key={index}
                      className={style.workingQueston}
                    >
                      <div></div>
                      <span
                      >{wq.text}</span>
                      <div className={style.workingQuestionType}>{wq.type || '(NO-TYPE)'}</div>
                    </li>
                  ))}
                </ul>
                <Button
                  className={style.modifyButton}
                  variant='contained'
                  onClick={() => navigate(`/${id}/agenda`)}
                  disabled={!!meeting.dateTimeEnded}
                >Change</Button>
              </>
            : <>
                <span
                  className={style.empty}
                >
                  You have no working questions! 
                  <br />
                  {!meeting.dateTimeEnded && 'Click on the button below to add some'}
                </span>
                <Button
                  className={style.modifyButton}
                  variant='contained'
                  onClick={() => navigate(`/${id}/agenda`)}
                  disabled={!!meeting.dateTimeEnded}
                >Add</Button>
              </>
          }
        </div>
        <div
          className={style.invitees}
        >
          <h2
            className={style.heading2}
          >
            {
              meeting.dateTimeEnded 
                ? 'List of all your participants'
                : 'List of everyone you have invited'
            }
            
          </h2>
          {meeting.invitees.length > 0
            ? 
            <>
              <ul
                className={style.propsList}
              >
                {meeting.invitees.map((invitee, index) => (
                  <li key={index}> {invitee.email}</li>
                ))}
              </ul>
              <Button
                className={style.modifyButton}
                variant='contained'
                onClick={() => navigate(`/${id}/invitees`)}
                disabled={!!meeting.dateTimeEnded}
              >Change</Button>
            </>
            :
            <>
              <span
                className={style.empty}
              >
                {`You haven't invited anyone!`}
                <br />
                Click on the button below to begin inviting.
              </span>
              <Button
                className={style.modifyButton}
                variant='contained'
                onClick={() => navigate(`/${id}/invitees`)}
                disabled={!!meeting.dateTimeEnded}
              >Invite</Button>
            </>
          }
          
        </div>
      </section>
      <section
        className={style.startWrapper}
      >
        <Button
          variant='contained'
          className={style.startButton}
          onClick={startMeeting}
          style={{
            display: hasMeetingStarted() ? 'none': 'inline-flex'
          }}
        >
          Start meeting
        </Button>
      </section>
    </div>
  )
}
