
import * as React from 'react'
import * as microsoftTeams from '@microsoft/teams-js'
import axios from 'axios'
import { useEffect } from 'react'
import { setupUserTheme } from '../functions/getUserTheme'
import { global } from '../../src/types/global'
import { useNavigate } from 'react-router-dom'

export default function TeamsLoading() {
  
  const baseUrl = process.env.REACT_APP_BASE_URL

  const navigate = useNavigate()

  const HALF_MIN_IN_MILI = 1000 * 30;

  const CACHE_EXP = HALF_MIN_IN_MILI;

  const [theme, setTheme] = React.useState<TeamsColorTheme>('default')

  const handleTheme = (context : microsoftTeams.app.Context) => {
    // TODO
  }

  const handleLang = (context : microsoftTeams.app.Context) => {

    const lang_short_list : string[] = [
      'de',
      'en'
    ]

    const user_lang_choice : string = context.app.locale;
    let language_set : boolean;

    console.log(`User language choice: ${user_lang_choice}`)

    lang_short_list.forEach(lang_short => {
      if(user_lang_choice.includes(lang_short)){
        localStorage.setItem('language', lang_short);
        language_set = true;
        console.log(`Setting language to: ${lang_short}`);
      }
    })
    
  }

  const navToOther = (page: any) => {

    console.log('Page:', page)
    if(page === ``){
      navigate(`/`)
      return
    }

    if(page === 'authenticate'){
      navigate('/authenticate')
      return
    }

    if(page === 'learn-more'){
      navigate(`/learn-more`)  
      return
    }

    console.log(`redirecting to /ms-teams/${page}`)
    window.location.href = `/ms-teams/${page}`
  }

  const onLoad = () => {    
    try{ microsoftTeams.app.initialize().then( () => { microsoftTeams.app.getContext().then(async context => {        
        
      let page_cache;
      try{
        page_cache = JSON.parse(localStorage.getItem('last-page') || '')
      }catch(e : any){
        console.log(`SYNTAX ERR WITH JSON PARSE`)
        page_cache = false;
      }
      
      handleLang(context);
      
      if(!context.meeting){        
        window.location.replace(`/ms-teams/pages/how-to`);
        return   
      }

      // const isCacheAvailable = page_cache &&
      //  Date.now() - page_cache.when_created < CACHE_EXP;

      // if(isCacheAvailable){
      //   console.log('redirecting using last page to ' + page_cache.last_page)
      //   window.location.replace(page_cache.last_page)
      //   return
      // }

      console.log("Request to server")
      try{
        await axios
          .post(`${baseUrl}api/teams-get-location`,{
            teamsContext: context
          })
          .then((response) => {
            console.log(response.data)
            navToOther(`${response.data.redirectUrl}`) 
          })
      }catch(e){
        navToOther(``)
      }

    })}).catch(e => {navToOther(``)})}catch(e){navToOther(``)}
  }
  
  useEffect(() => {
    onLoad()
    setupUserTheme(setTheme)
  }, [])

  return (
    
    <main
      style = {{
        height: '100vh',
        display: 'flex',
      }}
    >
      <p
        style = {{
          fontFamily: 'Figtree',
          textAlign: 'center',
          fontSize: '3rem',      
          fontWeight: '700',
          color : theme === 'default' ? 'black' : 'white'
        }}
      >
        Loading...
      </p>

    </main>
    
  )

}
