import * as React from 'react';
import * as microsoftTeams from '@microsoft/teams-js'
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import LoadingOverlay from './LoadingOverlay';

export default function TeamsCreateQnrBtn(props: {
  buttonText: string,
  qnrType: number,
  setLoading? : React.Dispatch<React.SetStateAction<boolean>>
}){

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation()

  const createQnr = (qnrType: number) => {
    microsoftTeams.app.initialize()
    .then( () => { microsoftTeams.app.getContext().then( (context) => {
      if(context && context.meeting){
        
        const meetingId = context.meeting.id
        const customId = Math.floor(100000 + Math.random() * 900000).toString();
        const qnrStr = uuid().slice(0, 12);
        const teamName = context.team?.displayName
        const creatorEmail = context.user?.userPrincipalName
        const body : any = {
          meetingId : meetingId,
          customId : customId,
          keyStr : qnrStr,
          qnrName: teamName,
          userEmail: creatorEmail,
          type: qnrType, 
        }
         
        axios
        .post(
          `${baseUrl}api/teams-create-qnr`,
          body
          )
        .then((response) => {
          window.location.href = `${baseUrl}ms-teams/show-qnr/${customId}/${qnrStr}`            
        })

        props.setLoading && props.setLoading(true);
      }
        
    })})
    .catch( (err) => {
      console.log('ERROR: ', err)
    })
  }

  return(
    <>

      <button
        className={`start-btn start-btn-${props.qnrType === 1 ? 'big' : 'small'}`}
        onClick={() => createQnr(props.qnrType)}
      >
        {t(props.buttonText)} 
      </button>
    </>
  )
}
