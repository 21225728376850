import axios from 'axios'
import style from './QuestionnaireBasicData.module.css'
import * as React from 'react';
import TypeIcon from '../TypeIcon';
import DeleteIconSvg from '../../images/Icon_delete_cross.svg'

export interface QuestionnaireBasicInfo {
  keyStr: string,
  dateCreated: string,
  voteCount: number,
  isShared: boolean,
  qnrName: string,
  type: number
}

type FontSize = `${number}rem`

export default function QuestionnaireBasicData(props: {
  customId: string,
  info?: QuestionnaireBasicInfo,
  togglePopUp?: (...props: any) => any,
  headingFontSize?: FontSize
}){

  const [qnrData, setQnrData] = React.useState<QuestionnaireBasicInfo>();

  const defaultHeadingFontSize : FontSize =  "2.5rem"  
  const toggleDeletePopup = props.togglePopUp;

  React.useEffect(() => {
    if(props.info){
      setQnrData(props.info);
      return;
    }

    const requestUrl = `${baseUrl}api/get-questionnaire-by-id`

    axios
    .get(requestUrl, {
      params: {
        qnrId: props?.customId
      }
    })
    .then( (result) => {
      if(result.data){
        setQnrData(result.data)
      }
    })
    
  }, []);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  return (
    <>
      {/*
        add the delete button to be rendered conditionally from props;
        add type icon to be rendered dependant on type from props;
      */}
      <section
        className={style.qnrHeadingsContainer}
      >
        <TypeIcon className={style.icon} type={qnrData?.type || 1}/>
        <p
          className={style.qnrName}
          style = {{
            fontSize: props.headingFontSize || defaultHeadingFontSize
          }}
          title={qnrData?.qnrName}
        >
          {qnrData?.qnrName || "Untitled questionnaire"}
        </p>
        <button
          className = {`${style.delQnrBtn} ${style.ignoreClose}`}
          style={{
            backgroundImage: `url(${DeleteIconSvg})`,
            display: toggleDeletePopup ? 'flex': 'none'
          }}
          onClick={props.togglePopUp}
        ></button>
      </section>

      <section
        className={style.qnrPropertiesCnt}
      >
        <span
          className={style.qnrProperty}
          title='created-when'
        >
          {`Created at: ${qnrData?.dateCreated || 'Unknown'}`}
        </span>

        <span
          className={style.qnrProperty}
          title='total-voters'
        >
          
          {
            'Voters:  '
          }
          {
            qnrData?.voteCount || 0
          }

        </span>

        <span
          className={style.qnrProperty}
          title='is-results-public'
        >
          {
            'Results: '
          }
          {
            qnrData?.isShared ? 
              'Public '
            :
              'Private '
          }
        </span> 

      </section>

    </>
  )
}
