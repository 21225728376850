import axios from 'axios'
import * as microsoftTeams from '@microsoft/teams-js'
import * as React from 'react'
import TeamsNavbar from '../components/TeamsNavbar';
import { setupUserTheme } from '../functions/getUserTheme';
import { global } from '../../src/types/global'
import '../styles/TeamsAuth.css'
import LoadingOverlay from '../components/LoadingOverlay';

export default function TeamsAuth(){

  const baseUrl = process.env.REACT_APP_BASE_URL

  const [theme, setTheme] = React.useState<TeamsColorTheme>('default')
  const [loading, setLoading] = React.useState(false);
	
  React.useEffect(() => {
    setupUserTheme(setTheme)
  }, [])


  const requestAuth = () => {
    console.log(`MAKING REQUEST`)
    axios
    .post(`${baseUrl}api/teams-auth`,
      {
        email : 'secret@email.com'
      }
    ).then(async(response) => {
      await microsoftTeams.app.initialize()
      const context = await microsoftTeams.app.getContext();
      
      const url = response.data?.redirectUrl;
      const verifier = response.data?.verifier;
      const loginHint = context.user?.loginHint;
      
      console.log(`REQUEST VALUED GOT URL ${response.data?.redirectUrl}`)
      console.log(`verifier: ${response.data?.verifier}`)
      
      const params : microsoftTeams.authentication.AuthenticatePopUpParameters = {
        url: url,
        width: 400,
        height: 400
        // isExternal: false
      }

      const result = await microsoftTeams.authentication.authenticate(params)
      
      console.log(result)
      console.log("auth finiesh")
      localStorage.setItem('afterLogInLocation', '/api/teams-jwt-auth')
      window.location.href = `/enter-account/${loginHint}/${verifier}?inTeams=asd`
    })
  }

  const handleButtonPress = () => {
    // setLoading(true);
    requestAuth();
  }

  return(
    <>
      <LoadingOverlay show = {loading} />
      <TeamsNavbar/>
      <main>
        <span
          id = 'auth-btn-pretext'
          className={`text ${theme}`}
        >
          To continue using Qck, we need to authenticate you! Please click the button below to proceed.
        </span>
        <button
          id = 'auth-btn'
          className={theme}
          onClick={handleButtonPress}
        >
          Authenticate
        </button>
        <span
          id = 'auth-btn-aftertext'
          className={`text ${theme}`}
        >
          *Clicking this link will briefly open an external page.
        </span>
      </main>
    </>
  )
}
