import { SetStateAction } from 'react'; import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ModalButtonGroup from './DurationButtonGroup/DurationButtonGroup';
import '../styles/setDurationModal.css';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import * as React from 'react';

export default function SetDurationModalV2(props: {
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  open: boolean;
  qnrId: string;
  duration?: number;
}) {
  const onCloseModal = () => props.setOpen(false);
  
  const continueButtonStyleActive = {
    borderRadius: '9px',
    padding: '18px 36px',
    fontSize: '1.5rem',
    color: 'white',
    backgroundColor: 'black',
    margin: '0 auto',
    display: 'block',
    cursor: 'pointer'
  }

  const continueButtonStyleInactive = {
    ...continueButtonStyleActive,
    backgroundColor: '#777777',
    cursor: 'default',
  }

  const [isDurationChosen, setIsDurationChosen] = useState(false)
  const [continueButtonStyle, setContinueButtonStyle] = useState(continueButtonStyleInactive)
  useEffect(() => {
    setContinueButtonStyle(
     isDurationChosen ? 
      continueButtonStyleActive :
      continueButtonStyleInactive
    )
  },[isDurationChosen, setIsDurationChosen])

  const { t } = useTranslation();

  return (
    <>
      <Modal
        classNames={{ modal: 'set-duration-modal' }}
        animationDuration={500}
        focusTrapped={false}
        open={props.open}
        onClose={onCloseModal}
        center
      >
        <h2 id="set-duration-modal-h2">{t('TIME SPENT IN MEETING (MIN.)')}</h2>
        <ModalButtonGroup
          qnrId={props.qnrId}
          duration={props.duration}
          setIsDurationChosen={setIsDurationChosen}
        />
        <Button
          onClick={() => {
            if({isDurationChosen}.isDurationChosen){
              props.setOpen(false);
              location.reload();
            }            
          }}
          style={
            continueButtonStyle
          }
        >
          {t('Continue')}
        </Button>
      </Modal>
    </>
  );
}
