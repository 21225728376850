import * as React from 'react';

export default function CountCnt(props: {
  property : string,
  count : number,
  unit? : string,
}){
  return (
    <div
      className='count-cnt'
    >
      <section
        className='count-cnt-content'
      >
        <span
          className='count-cnt-num'
        >
          {props.count}
        </span>

       {props.unit && 
        <span
          className='count-cnt-unit'
        >
          {props.unit}
        </span>
       }

      </section>
      <section
        className = 'count-cnt-heading-cnt'
      >
        <span
          className='count-cnt-heading'
        >
          {props.property}
        </span>
      </section>

      
    </div>
  )
}