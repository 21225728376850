import axios from 'axios';
import * as React from 'react'
import '../styles/results.css';
import SwitchResultsPrivacy from './SwitchResultsPrivacy';
import Dots from './Dots';
import { t } from 'i18next';
import ModalButtonGroup from './DurationButtonGroup/DurationButtonGroup';

export interface QuestionnaireData {
  qnrId: string,
  qnrStr: string,
  votes: Vote[],
  shared: boolean,
  duration: number
}

export default function QuestionnaireSettings(props: {
  customId: string,
  data?: QuestionnaireData,
  setQnrStrForVoteSuccess?: React.Dispatch<React.SetStateAction<string>>
}){

  const [qnrData, setQnrData] = React.useState<QuestionnaireData>()

  React.useEffect(() => {

    if(props.data){
      setQnrData(props.data)
      return
    }

    const baseUrl = process.env.REACT_APP_BASE_URL;

    axios
    .get(`${baseUrl}api/get-questionnaire-by-id`, {
      params: {
        qnrId: props.customId
      }
    })
    .then(result => {
      if(result.status === 200 && result.data){
        const qnrDataFromResult = {
          qnrId: result.data?.customId,
          qnrStr: result.data?.keyStr,
          votes: result.data?.votes,
          shared: result.data?.shared || false,
          duration: result.data?.duration,
        } as QuestionnaireData;

        props.setQnrStrForVoteSuccess && props.setQnrStrForVoteSuccess(qnrDataFromResult.qnrStr);
        setQnrData(qnrDataFromResult)
      }
    }).catch((error) => {
      console.log('error: ')
      console.log(error)
    })
  }, [])


  return (
    <>
      {!qnrData ?   
    
        <>
        </>

          :
        <>
          <div
            className="two-element-flexbox voters-results"
            style = {{
              flexWrap: 'nowrap'
            }}
          >
            <span className="privacy-checkbox-label">
              {t('Voters')}: {(qnrData.votes && qnrData.votes.length) || 0}
            </span>
            {qnrData.qnrId && qnrData.qnrStr && (
              <SwitchResultsPrivacy
                qnrId={qnrData.qnrId}
                qnrStr={qnrData.qnrStr}
                shared={qnrData.shared || false}
              />
            )}
          </div>
          <div className="detailed-vote-wrapper">
            <span className="rating-label">Meeting duration:</span>
            {qnrData.qnrId && qnrData.qnrStr && (
              <ModalButtonGroup
                qnrId={qnrData.qnrId}
                duration={qnrData.duration}
              />
            )}
          </div>
        </>
      }
    </>
  )


}
