import * as React from "react";

import style from './MeetingRouter.module.css';

import {Route, Routes, NavLink, Navigate, useParams, useNavigate} from "react-router-dom";
import Methodology from "./Methodology/Methodology";
import Agenda from "./Agenda/Agenda";
import Meeting from "./Meeting/Meeting";
import StartedMeeting from "./StartedMeeting/StartedMeeting";
import Invitees from "./Invitees/Invitees";
import EvaluationHostPage from "./Evaluation/Evaluation";
import {useTranslation} from "react-i18next";
import Navbar from "../../Navbar";
import UserAgreement from "../../components/UserAgreementLink";
import axios from "axios";
import {Button} from "@mui/material";

export default function OwnerMeetingRouter() {

  const { t } = useTranslation();

  const { id } = useParams();
  const navRef = React.useRef<HTMLDivElement>(null);

  const [ meeting, setMeeting ] = React.useState<Meeting>();

  const navigate = useNavigate();

  const [ isMeetingStarted, setIsMeetingStarted ] = React.useState<boolean>();
  const [ isMeetingEnded, setIsMeetingEnded ] = React.useState<boolean>();

  const [ userRole, setUserRole ] = React.useState<Role>('User');

  const fetchMeetingStatus = () => {
    axios
    .get('/api/meeting', { params: { meetingId: id } })
    .then((response) => {
      const meeting = response.data.meeting as Meeting;
      setIsMeetingStarted(!!meeting.dateTimeStarted);
      setIsMeetingEnded(!!meeting.dateTimeEnded);
      setMeeting(meeting);
      //fetch user email from sessionStorage and then fetch user role from invitees 
      const userEmail = sessionStorage.getItem('qckEmail');
      meeting.invitees.forEach(invitee => {
        if(invitee.email === userEmail)
          setUserRole(invitee.role);
      })

    })
  }

  const setInvitees = (_new: Invitee[] | ((curr: Invitee[]) => Invitee[])) => {
    let newInvitees: Invitee[];
    if(typeof _new === 'function'){
      newInvitees = _new(meeting?.invitees || []);
    }else{
    }
  }

  const setWorkingQuestions = (workingQuestions: WorkingQuestion[]) => {
    setMeeting({...meeting, workingQuestions} as Meeting);
  }

  React.useEffect(fetchMeetingStatus, []);

  return (
    <>
      <Navbar />

      <div className={style.navWrapper}
        ref={navRef}
      >
        <div
          className={style.backToMeetingWrapper} 
          style={{
            display: isMeetingStarted && !isMeetingEnded ? 'flex': 'none',
          }}
        > 
          <Button
            className={style.backToMeetingButton}
            onClick={() => navigate(`/${id}/start`)}
            variant="contained"
            style={{
              fontSize: '1.5rem',
            }}
          >Back to meeting</Button>
        </div>
        <nav className={style.nav}>
          <NavLink
            to = {`/${id}/agenda`}
            className={state => {
              return `${style.navLink} ${state.isActive ? style.active : style.navLink}`
            }}
          >
            {t('Agenda')}
          </NavLink>
          <NavLink
            to = {`/${id}/invitees`}
            className={state => {
              return `${style.navLink} ${state.isActive ? style.active : style.navLink}`
            }}
          >
            {t('Invitees')}
          </NavLink>
          <NavLink
            to = {`/${id}/meeting`}
            className={state => {
              return `${style.navLink} ${state.isActive ? style.active : style.navLink}`
            }}
          >
            {t('Meeting')}
          </NavLink>
          <NavLink
            to = {`/${id}/evaluation`}
            className={state => {
              return `${style.navLink} ${state.isActive ? style.active : style.navLink}`
            }}
          >
            {t('Evaluation')}
          </NavLink>
          {/* <NavLink
            to = {`/${id}/methodology`}
            className={state => {
              return `${style.navLink} ${state.isActive ? style.active : style.navLink}`
            }}
          >
            {t('Help')}
          </NavLink> */}
        </nav>
      </div>

      <section
        className={style.contentWrapper}
      >
        <div
          className={style.content}
        >
          {/*TODO add loading, remove context, populate all fields using props=*/}
          {meeting ?
            <Routes>

              <Route path='/agenda' element={<Agenda meeting={meeting} setWorkingQuestions={setWorkingQuestions} userRole={userRole} />} />
              <Route path='/methodology' element={<Methodology />} />
              <Route path='/invitees' element={<Invitees invitees={meeting.invitees} dateTimeEnded={meeting.dateTimeEnded} setInvitees={setInvitees} />} />
              <Route path='/meeting' element={<Meeting meeting={meeting} />} />
              <Route path='/evaluation' element={<EvaluationHostPage meeting={meeting} />} />
              <Route path='/start' element={<StartedMeeting navRef={navRef} meeting={meeting}/>} />
              <Route path='*' element={<Navigate to={`/${id}/agenda`} replace />} />

            </Routes>
          : <></>}
        </div>
      </section>
      <UserAgreement />
    </>
  )
}

