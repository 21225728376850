import * as React from 'react';
import Navbar from '../Navbar';
import UserAgreement from '../components/UserAgreementLink';
import '../styles/CustomQuestion.css'
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { useLocation } from 'react-router-dom';

export default function CustomQuestion(){

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultName = queryParams.get('defaultName');
  console.log(defaultName)

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const formRef = React.useRef<HTMLFormElement>(null);

  localStorage.setItem('language', 'de');

  const start = () => {

    const customId = Math.floor(100000 + Math.random() * 900000).toString();
    const keyStr = uuid().slice(0, 12);
    const name = inputRef.current?.value;
    
    const url = `${baseUrl}api/start-custom?keyStr=${keyStr}&customId=${customId}&name=${name}`
    localStorage.setItem('afterLogInLocation', url);

    window.location.href = url;
    // axios
    // .get(url)
    // .then((response) => {
    //   console.log('result: ')
    //   console.log(response)
    //   window.location.href = `${baseUrl}dashboard/${customId}/${keyStr}`;
    // }).catch((e) => {
    //   const status = e.response.status;
    //   console.log('error: \n', e.response.status)
    //   // localStorage.setItem('afterLogInLocation', url);
    //   // window.location.href = '/login'
    // })
    
    

  }

  const handleSubmit : React.FormEventHandler = (event) => {
    event.preventDefault();
    const name = inputRef.current?.value;
    if(!name){
      formRef.current?.submit();
      return
    }

    start();
  }



  return (
    <>
      <Navbar />
      <main>

        <h1
          id = 'cq-heading'
        >
          Starten Sie Ihren Fragebogen!
        </h1>

        <form
          id = 'cq-content'
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <label htmlFor="">
            Geben Sie den Namen des Fragebogens ein
          </label>
          <input
            ref={inputRef}
            type="text"
            placeholder='Hier eingeben'
            required={true}
            defaultValue={defaultName || ''}
          />
          <button>
            Einreichen
          </button>
        </form>
      </main>
      <UserAgreement />
    </>
  )
}
