import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { useEffect, useState } from 'react'
import '../index.css'
import '../styles/TeamsQnrResults.css'
import TeamsDeleteQnrBtn from '../components/TeamsDeleteQnrBtn'
import * as microsoftTeams from '@microsoft/teams-js'
import { useTranslation } from 'react-i18next'
import { setupUserTheme } from '../functions/getUserTheme'
import { ReactComponent as ExternalLinkIcon } from '../images/Icon-external-link.svg'
import { v4 as uuid } from 'uuid';
import TeamsNavbar from '../components/TeamsNavbar'
import * as React from 'react'
import LoadingOverlay from '../components/LoadingOverlay'

export default function TeamsQnrResults(){

  const [theme, setTheme] = useState(`default`)

  const baseUrl = process.env.REACT_APP_BASE_URL || '';
  const { customId, keyStr } = useParams();

  // const qnrUrl = `${baseUrl}results/${customId}/${keyStr}`
  const [ qnrUrl, setQnrUrl ] = React.useState<string>();
  const [ loading, setLoading ] = React.useState<boolean>(false);

  const { t } = useTranslation()

  useEffect(() => {
    setupUserTheme(setTheme)
  }, [])

  const redirectNewWindow = () => {
    if(qnrUrl)
      window.open(qnrUrl, '_blank')
  }

  React.useEffect(() => {
    const generateUrlWithLoginHint = async () => {
      await microsoftTeams.app.initialize();
      const context : microsoftTeams.app.Context = await microsoftTeams.app.getContext();
      const email : string | undefined = context.user?.loginHint;
      if(!email)
        return setQnrUrl(`${baseUrl}results/p/${customId}`);
      
      axios
      .get(`${baseUrl}api/generate-login-hint`, {
        params: {
          email: context.user?.loginHint
        }
      }).then((response) => {
        const token = response.data?.token;
        if(token)
          return setQnrUrl(`${baseUrl}results/${customId}/${keyStr}?token=${token}&loginHint=${email}`);;

        return setQnrUrl(`${baseUrl}results/${customId}/${keyStr}`);
      }).catch(() => setQnrUrl(`${baseUrl}results/${customId}/${keyStr}`))
    }
    
    generateUrlWithLoginHint();
  }, [])

  React.useEffect(() => {
    console.log(qnrUrl)
  }, [qnrUrl, setQnrUrl])

  return (
    <>
      <LoadingOverlay show = {loading}/>
      <div>

        <nav>
          <TeamsNavbar theme={theme}/>
        </nav>
        
        <main>
          <p
            style={{
              fontFamily : 'Figtree',
              textAlign: 'center',
              fontSize: '2rem',
              width: '60%',
              fontWeight: '600'
            }}
            className={theme}
          >
            {t(`A link to access the questionnaire has been sent to everyone in the meeting.`)}
          </p>

          <p
            className={`redirect-btn-label ${theme}`}
          >
            {t(`To see the results`)}
          </p>

          <button          
            className={`redirect-btn ${theme}`}
            onClick= {() => redirectNewWindow()}
          >
            <p>
              {t('Show results')}
            </p>
            <ExternalLinkIcon/>
          </button>
          
          <p
            className={`redirect-btn-label ${theme}`}
          >
            {t("Start a new questionnaire?")}
          </p>

          <TeamsDeleteQnrBtn theme={theme} setLoading={setLoading}/>
          
        </main>
      </div>
    </>
      )
}
