import * as React from 'react';
import RightArrow from '../images/Icon-right-arrow.svg';
import { useTranslation } from 'react-i18next';
import Questionnaire from './Questionnaire/Questionnaire';
import '../styles/QuestionnairesList.css'
import { global } from '../types/global'
import axios from 'axios';
import {useSearchParams} from 'react-router-dom';
import Dots from './Dots';

export default function QuestionnairesList(props: {
  regularMeetingId?: string;
  setPopUpQnrId?: any;
}) {

  const QUESTIONNAIRES_PER_PAGE = 15;
  
  const { regularMeetingId } = props;
  const [ searchParams, setSearchParams ] = useSearchParams();

  const [ qnrsInPage, setQnrsInPage ] = React.useState<Questionnaire[]>([]);
  const [ totalQnrCount, setTotalQnrCount ] = React.useState<number>();
  const [ pageCount, setPageCount ] = React.useState<number>();
  const [ loading, setLoading ] = React.useState(true);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation()
  
  const parsePage = (pageCount?: number): number => {
    const pageCandidate = searchParams.get('page');

    if(!pageCandidate) return 0;

    let parsedPage;
    try{
      parsedPage = parseInt(pageCandidate);
    } catch {
      return 0;
    }

    return parsedPage;
  }

  const fetchQuestionnaires = () => {

    const url = `${baseUrl}api/get-questionnaires`
    axios
    .get(url, {params: {
      page: parsePage(), regularMeetingId
    }}).then( result => { 
      const _totalQnrs = result.data.totalQnrs; 
      const _qnrs = result.data.qnrs;
      const _pageCount = Math.ceil(_totalQnrs / QUESTIONNAIRES_PER_PAGE);
      setLoading(false);
      setTotalQnrCount(_totalQnrs);
      setPageCount(_pageCount);
      setQnrsInPage(_qnrs);
    })
    setQnrsInPage([]);
    setLoading(true);
  }
  React.useEffect(fetchQuestionnaires, [searchParams])
  
  
  return (
    <>
      {!loading && qnrsInPage.length !== 0 ?
      <ul
        id = 'questionnaires-list'
      >
        {qnrsInPage
        .map((item, index) => (
          <Questionnaire
            content =    {item}
            key =        {index}
            setPopUpQnrId = {props.setPopUpQnrId}
          />
        ))  }                  
        
        <section
          className='pages-nav-cnt'
        >
          <div
            className='info-cnt'
          >
            <span>
              {t('Showing')} {QUESTIONNAIRES_PER_PAGE * parsePage() + 1}-{Math.min(QUESTIONNAIRES_PER_PAGE * parsePage() + QUESTIONNAIRES_PER_PAGE, totalQnrCount || 0)} / {totalQnrCount}
            </span>
            <span>
              {t('Page')} : {parsePage() + 1} / {pageCount}
            </span>
          </div>
          <div
            className='button-cnt'
          >
            <button
              className='left'
              style = {{
                backgroundImage: `url(${RightArrow})`
              }}
              onClick = {
                () => setSearchParams({'page': (parsePage() - (parsePage() - 1 >= 0 ? 1:0) * 1) + ''})
              }
            ></button>
            <button
              className='right'
              style = {{
                backgroundImage: `url(${RightArrow})`
              }}
              onClick = {
                () => setSearchParams({'page': (parsePage() + (parsePage() + 1 < (pageCount || 0) ? 1:0) * 1) + ''})
              }
            ></button>
          </div> {/* button-cnt */}
        </section>
      </ul>
      :
        loading ?
      <span
        style={{
          fontSize: '4.5rem',
          marginTop: '2rem'
        }}
      >
        {t('Loading')}<Dots />
      </span>
      :
      <span
        style={{
          fontSize: '4.5rem',
          marginTop: '2rem'
        }}
      >
        {t('There are no questionnaires on this page!')}
      </span>
      }
    </>
  )
}
