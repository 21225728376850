
/**
 * if a number N is less than 10 it returns 0N, else it returns N as string
 */
const parseNumber = (N: string | number): string => {
  const N_as_string = N + '';
  const N_as_number = parseInt(N_as_string);
  return N_as_number < 10 ? `0${N_as_number}` : N_as_string;
}

export default parseNumber;
