import * as React from 'react';
import style from './Editor.module.css';

import { Button } from '@mui/material';

//Editor
import { MDXEditor, headingsPlugin, MDXEditorMethods } from '@mdxeditor/editor';
import { UndoRedo, BoldItalicUnderlineToggles, toolbarPlugin, ListsToggle, listsPlugin } from '@mdxeditor/editor'

export default function Editor(props: {
  minute: Minute
  isCurrent: boolean
  onResultsClick?: React.Dispatch<React.SetStateAction<boolean>>
  onFeedBackClick?: React.Dispatch<React.SetStateAction<boolean>>
  onEditorChange: (markdown: string) => void
  hasVotingEnded: boolean;
  hasAllPermissions?: boolean;
}){

  const { hasVotingEnded } = props;
  
  const editorRef = React.useRef<MDXEditorMethods>(null);

  React.useEffect(() => {
    if(
      !editorRef.current ||
      props.minute === undefined ||
      props.minute.text === undefined
    ){
      return;
    }


    editorRef.current.setMarkdown(props.minute.text);
  }, [props.minute])

  return (
    <div
      className={style.dropdown}
      style={{
        display: props.isCurrent ? 'block' : 'none'
      }}
    >
      <MDXEditor 
        markdown={''}
        ref={editorRef}
        plugins={[headingsPlugin(), toolbarPlugin({
          toolbarContents: () => (
            <>
              {'  '}
              <UndoRedo />
              <BoldItalicUnderlineToggles />
              <ListsToggle />
              {props.hasAllPermissions && (!hasVotingEnded
                ?
                  <Button
                    className={style.startVotingButton}
                    variant='contained'
                    style={{
                      fontSize: '1.5rem',
                      marginLeft: 'auto'
                    }}
                    onClick={ () => props.onFeedBackClick && props.onFeedBackClick(true) }
                  > Start feedback </Button>
                :
                  <Button
                    className={style.startVotingButton}
                    variant='contained'
                    style={{
                      fontSize: '1.5rem',
                      marginLeft: 'auto'
                    }}
                    onClick={ () => props.onResultsClick && props.onResultsClick(true) }
                  > View results </Button>)
              }
            </>
          )
        }), listsPlugin()]} 
        onChange={props.onEditorChange}
      />
    </div>
  )
}
