import * as React from 'react';
import style from './Results.module.css';
import { TYPE_QUESTIONS_MAP, MAX_QUESTION_ANSWER } from '../../../../constants';
import EvaluationGraph from '../../EvaluationGraph';

export default function Results(props:{
  type: MeetingType,
  feedback: {
    answers: Answer;
    opinions: Opinion;
    votingStartedDateTime?: number;
    votingEndedDateTime?: number;
  }
  emailColorMap: Record<Email, string>
}) {
  const { type, feedback } = props;

  const sliderRef = React.useRef<HTMLDivElement>(null);

  const [openedDetailFields, setOpenedDetailFields] = React.useState<number[]>([]);
  const getSliderWidth = (): number => {
    if(!sliderRef.current) return -1;

    return sliderRef.current.clientWidth - 20|| 0;
  }

  const Circle = (props: {
    letter: string
    color: string
    yOffset: number
    style?: any
  }) => {
    return (
      <span 
        style={{
          backgroundColor: props.color,
          transform: `translateY(${-props.yOffset * 5}px)`,
          ...props.style
        }} 
        className={style.circle}
      >
        {props.letter}
      </span>
    )
  }

  const CircleGroup = (props: {
    children: React.ReactElement[]
    leftOffset: number
  }) => {
    return (
      <div 
        className={style.circleGroup}
        style={{
          transform: `translateX(${props.leftOffset * getSliderWidth() * MAX_QUESTION_ANSWER * 0.01}px)`
        }}
      >
        {props.children}
      </div>
    )
  }

  const Slider = (props: {
    children: any
  }) => {
    return (
      <div className={style.slider} ref={sliderRef}>
        {props.children}
      </div>
    )
  }

  //Calcs to determine which votes are repeating and need to be grouped
  //Returns a list of objects as {answerValue: number, letters: []}
  type ValuesAndLettersWithColors = {
    answerValue: number,
    lettersWithColors: {
      color: string,
      letter: string
    }[]
  }[];

  const createValuesAndLetters = (feedbackQuestionIndex: number): ValuesAndLettersWithColors => {

    //Answer value to array of first letters of emails map :)
    const list: Record<number, {letter: string, color: string}[]> = {};

    for(const email in feedback.answers){
      const value = feedback.answers[email][feedbackQuestionIndex];
      if(list[value] === undefined){
        list[value] = [];
      }

      list[value].push({
        letter: email.charAt(0),
        color: props.emailColorMap[email]
      });
      
    }

    const valuesAndLetters: ValuesAndLettersWithColors = [];
    for(const value in list){
      valuesAndLetters.push({
        answerValue: parseInt(value),
        lettersWithColors: list[value]
      })
    }
    
    return valuesAndLetters;
  }

  const toggleDetailsField = (feedbackQuestionIndex: number) => {
    setOpenedDetailFields(curr => {
      if(curr.includes(feedbackQuestionIndex)){
        return curr.filter((el) => el != feedbackQuestionIndex);
      }
      return [...curr, feedbackQuestionIndex];
    })
  }
  return (
    <>
      <ul className={style.feedbackQuestionList}>
        {/*question*/}
        {/*slider*/}
        {/*details*/}
        {/*in details -> list of (L) n/N Name: Answer */}
        {TYPE_QUESTIONS_MAP[type].map((feedbackQuestion, index) => {
          return (
            <li key={index} className={style.feedbackQuestion}>
              <p className={style.questionText}>{feedbackQuestion}</p>
              <Slider>
                {createValuesAndLetters(index).map((valuesAndLettersWithColors, _) => {
                  return (
                    <CircleGroup key={_} leftOffset={valuesAndLettersWithColors.answerValue}>
                      {valuesAndLettersWithColors.lettersWithColors.map((letterWithColor, index) => (
                        <Circle letter={letterWithColor.letter} color={letterWithColor.color} key={index} yOffset={index}/>
                      ))}
                    </CircleGroup>
                  )
                })}
              </Slider>
              <div className={style.details} onClick={() => toggleDetailsField(index)}>
                <h1 className={style.detailsHeading}>
                  Details
                </h1>
                {openedDetailFields.includes(index) && Object.keys(feedback.opinions).map((email, _index) => (
                  <p className={style.opinion} key={index}>
                    <span>
                      <Circle letter={email.charAt(0)} yOffset={0} color={props.emailColorMap[email]} style={{position: "relative"}} />
                      {feedback.answers[email][index]}/{MAX_QUESTION_ANSWER}:
                    </span>
                    {feedback.opinions[email][index]}
                  </p>
                ))}
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}
