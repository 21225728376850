import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import style from './ChooseType.module.css';
import Navbar from '../../Navbar';
import { Fieldset } from 'primereact/fieldset';
import { NUMBER_TYPE_TYPE_MAP } from '../../constants';
import { TYPE_POINTS_MAP } from '../../constants';
import UserAgreementLink from '../../components/UserAgreementLink';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import TypeIcon from '../../components/TypeIcon';
import axios from 'axios';
import { global } from '../../types/global';

import './ChooseType.css';
import { create } from 'domain';

export default function ChooseType() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const createMeeting = (type:MeetingType) => {
    axios
    .post('/api/meeting-new',{type:type})
    .then((response) => {
      const meetingId = response.data.id;
      navigate(`/${meetingId}`);
    })
    .catch((reason) => {
      const errorCode = reason.response.status;
      if(errorCode === 401){
        localStorage.setItem('afterLogInLocation', '/');
        window.location.href='/login'
      }
    })
  }
  return (
    <>
      <Navbar />
      <div style={{display:'flex',flexDirection:'column'}}>  
        <a  className={style.p1}  id = 'page-heading'>{t('How would you like to improve your team performance today?')}</a>
        <a className={style.p2}> {t('Choose from 4 ways to develop your team and improve the efficiency to achieve better results in just 2 minutes!',)}</a>
        <br />
        <div className={style.mainContent}>
          {Object.values(NUMBER_TYPE_TYPE_MAP).map((value, key) =>{
            const convert = (key+1) as unknown as MeetingType
            return (
              <Fieldset legend={t(value)} className={style.fieldset} key={key}>
                <div className={style.fieldsetcont}>
                  <TypeIcon type={convert} className={style.distributionCard} />
                  <ul className={style.lineCont}>
                    {TYPE_POINTS_MAP[convert].map((text,key) => (
                      <li key={key} className={style.line}>
                        {t(text)}
                      </li>
                    ))}
                  </ul>
                  <Button label={"Start " + t(NUMBER_TYPE_TYPE_MAP[convert])} className={style.startButton} raised p-ripple onClick={() => createMeeting(convert)}/>
                </div>
              </Fieldset>
            )})};
        </div>
      </div>
      <div style={{ paddingTop: '20px' }}>
        <UserAgreementLink />
      </div>
    </>
  )
}

