import { useTranslation } from 'react-i18next';
import '../../styles/DeleteRegularMeetingPopup.css'
import * as React from 'react';
import axios from 'axios';


export default function DeleteRegularMeetingPopup(props: {
  customId: string,
  regularMeetingName: string,
  setDeletePrompted: React.Dispatch<React.SetStateAction<boolean>>
}){

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [ isNameEntered, setIsNameEntered ] = React.useState<boolean>(false);

  const handleInputChange : React.ChangeEventHandler = (event) => {
    const input = (event.target as HTMLInputElement).value;
    setIsNameEntered(
      input.toLowerCase() === props.regularMeetingName.toLowerCase()
    )
  }

  const cancelDelete = () => {
    props.setDeletePrompted(false);
  }

  const deleteRegularMeeting : React.FormEventHandler= (event) => {
    event.preventDefault();
    if(!isNameEntered && inputRef.current){
      inputRef.current.focus();
      return;
    }

    const deleteUrl = `${baseUrl}api/delete-regular-meeting`

    axios
    .delete(deleteUrl, {
      params: {
        customId: props.customId
      }
    }).then((result) => {
      props.setDeletePrompted(false);
      window.location.href = '/'
    })

  }

  return (
    <section
      id = 'rm-confirm-delete-cnt'
    >
      <h1
              
      >
        {t('Are you sure you want to delete this regular meeting?')}
      </h1>
      <p>
        {t('All the questionnaires created from it will be deleted as well.')}
      </p>

      <form
        id = 'rm-confirm-input-form'
        onSubmit={deleteRegularMeeting}
      >
        <label
          htmlFor="rm-name-input"
        >
          {`* ${t(`Required`)}`}
        </label>
        <input
          type="text"
          id = 'rm-name-input'
          form='rm-confirm-input-form'
          required = {true}
          placeholder={props.regularMeetingName}
          onChange={handleInputChange}
          autoComplete='off'
          autoCorrect='off'
          ref={inputRef}
        />
        <span
          className='rm-confirm-delete-text'
        > 
          {t('Enter the name of the meeting to confirm you want to delete it')}
        </span>
        <div
          className='two-el-flexbox'
        >
          <button
            type='button'
            className='cancel-btn'
            onClick={cancelDelete}
          >
            {t('Cancel')}
          </button>
          <button
            form='rm-confirm-input-form'
            className={`confirm-btn ${isNameEntered ? 'active' : ''}`}
          >
            {t('Confirm')}
          </button>
        </div>
      </form>
    </section>
  )
}
