export const LONG_POOL_LENGTH = 1000;
export const OTP_LENGTH = 4;
export const QUESTIONNAIRES_PER_PAGE = 15;
export const MIN_QUESTION_ANSWER = 0;
export const MAX_QUESTION_ANSWER = 10;
export const POOLING_TIMEOUT = 2000;
export const FEEDBACK_QUESTIONS: FeedbackQuestion[] = ['The discussion was constructive', 'I know what I have to do'];
export const WORKING_QUESTION_TYPES = ['information', 'discussion', 'decision'];
export const DURATION_JUMP_IN_MS = 10000;
export const ROLE_PERMISSION_MAP: Record<Role, Permission[]> = {
  'User': [],
  'Owner': ['all', 'agenda.new', 'agenda.reorder', 'agenda.edit.ours', 'agenda.reorder', 'agenda.remove', 'minute.contribute.always', 'invitee.contribute', 'agenda.backlog'],
  'Co-Owner': ['all', 'agenda.new', 'agenda.reorder', 'agenda.edit.ours', 'agenda.reorder', 'agenda.remove', 'minute.contribute.always', 'invitee.contribute', 'agenda.backlog'],
  'Contributor': ['minute.contribute.onallow', 'agenda.new', 'agenda.edit.ours'],
  //'Owner': ['minute.contribute.always', 'agenda.new', 'agenda.edit.ours'],
}
export const NUMBER_TYPE_TYPE_MAP: Record<MeetingType, string> = {
  1: 'Meeting Follow-Up',
  2: 'Team Check-In',
  3: 'Mission Statement Check',
  4: 'Change Communication Survey'
}
export const TYPE_QUESTIONS_MAP: Record<MeetingType, FeedbackQuestion[]> = {
  1: [
    'The process, how we have achieved the result, was efficient.',
    'I know exactly what I have to do. I deliver my contribution at the agreed time in the agreed quality.',
    'We have discussed even controversial issues in an appreciative way. Everyone contributed.',
    'The result takes us further'
  ],
  2: ['I know the goals for our team and what we are working for.',
      'My role and responsibilities in the team are clear to me, I feel up to my tasks.',
      'I receive all contributions within our team at the agreed time and in the agreed quality.',
      'When problems arise, we have a common understanding of how to solve them quickly and effectively.',
      'We resolve conflicts promptly and with the involvement of those affected.',
      'We have common values that we can orientate ourselves by and that unite us. I can name at least two.',
      'When there is a need for change, we respond quickly as a team and adapt to the new demands.',
      'We regularly take time to work out in a structured way how we can strengthen our performance as a team and our cohesion.' 
  ],
  3: ['We are getting down to business!',
      'Tasks are finished in a way, that those who continue to work with the results, can do that efficiently.',
      'Commitments are kept.',
      "Even when things get tough, we don't lose the fun in what we do."
    ],
  4: ['I have a clear picture of what should be different and better after the change than before.',
      'It is easy for me to convince others of the necessity of change.',
      'We take enough time to talk about new rules of cooperation and how they serve the change objective.',
      'When something is communicated with us, I can trust that it is correct. Even official announcements turn out to be true.',
      'I can speak openly if something in the change does not go as planned.'
  ]
}
export const TYPE_HELPER_MAP: Record<MeetingType, string[]> = {
  1: ['What else is needed at this stage? What might have we missed?' , 'What do we want to pay attention to next time?' ,  'What do we want to pay attention to next time?' , 'What else do I need to deliver my contribution reliably?'],
  2: ['What information am I missing?','What else do I need to fulfil my role?','Where specifically should we as a team focus on reliability?','What should we change to become more efficient?','What should we change to deal with conflicts more constructively?','Which values should we strengthen? How?','What do we need to respond even more quickly to new demands?','What should we do to get into a regular development routine?'],
  3: ['How could we motivate more proactivity?','What should we do to improve the quality of our results?','How can we improve reliability in the organisation?','What would help me find more joy in my work?'],
  4: ['What questions are open for me?','What arguments would help me to convince others?','What specifically should we change?','What do I need specifically to be able to trust official information, especially during changes?','What is generally missing in our culture to support more openness?']
}

export const TYPE_POINTS_MAP: Record<MeetingType, string[]> = {
  1:['Improved meeting results','Continuous increase in meeting efficiency','Strengthening the team culture','More commitment to implementation'],
  2:['Strengthen your team cooperation in the long term','Focus on what is relevant and helps the team to increase impact','Start a dialogue to strengthen and develop the team success factors'],
  3:['Bring more momentum into your organization','Challenge commitment, result-orientation and fun at work. Start a dialogue to strengthen and develop the impact of your mission'],
  4:['Manage your change with impact by ensuring clear communication.','Create a trusting environment where the whole team has its say Start a dialogue to support your change-impact!']
}

export const colorPallete = [ '#93C3AB', '#78A1BE', '#05c73b', '#0cd45b', '#0947fd', '#0e21eb', '#038a8d', '#00edca', '#005f79', '#0162b2', '#0835b9', '#052bd0', '#0c7b77', '#04443f', '#06639d', '#075ae0', '#00bf76', '#000157', '#04fc3b', '#0ab42b', '#024abf', '#0b24a6', '#02b637', '#062648', '#0e6b06', '#087805', '#052f39', '#0c4d0b', '#0132fd', '#00b66a', '#0b5aeb', '#097692', '#077b61', '#05bae8', '#0d1804', '#09710d', '#00b43f', '#0a1a87', '#0e547b', '#063fce', '#07381b', '#04d22d', '#01af29', '#0ed51a', ];
