import * as React from 'react';

import style from './ResultsPopup.module.css';
import Results from './Results';
import { Button } from '@mui/material';
import axios from 'axios';

export default function ResultsPopup(props: {
  type?: MeetingType,
  feedback?: {
    answers: Answer;
    opinions: Opinion;
    votingStartedDateTime?: number;
    votingEndedDateTime?: number;
  }
  continueAction: () => void
  inviteesList?: Invitee[]
  id?: string
}) {

  const [loading, setLoading] = React.useState(true);

  const [type, setType] = React.useState<MeetingType>(props?.type || 1);
  const [feedback, setFeedback] = React.useState<{
    answers: Answer;
    opinions: Opinion;
    votingStartedDateTime?: number;
    votingEndedDateTime?: number;
  }>(props?.feedback || {answers: {}, opinions: {}});
  const [inviteesList, setInviteesList] = React.useState<Invitee[]>(props.inviteesList || []);

  const [id, setId] = React.useState<string>(props.id || '');

  React.useEffect(() => {
    if(!(Object.keys(feedback.answers).length <= 0 || Object.keys(feedback.opinions).length <= 0 && props.id)){
      return;
    }

    axios
    .get('/api/meeting', { params: { meetingId: id } })
    .then((response) => {
      const meeting = response.data.meeting as Meeting;
      setType(meeting.type);
      setFeedback(meeting.feedback[meeting.currentWorkingQuestionIndex]);
      setInviteesList(meeting.invitees);
      setLoading(false);
    })
  }, [])

  return (
    <div className={style.container}>
      <h1 className={style.heading1}>
        Results
      </h1>

      <Results type={type} feedback={feedback} emailColorMap={Object.fromEntries(inviteesList.map(({email, color}) => [email, color]))}/>

      <Button
        variant='contained'
        className={style.continueButton}
        onClick={props.continueAction}
      >Continue</Button>
    </div>
  )
}

