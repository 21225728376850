import * as React from 'react';

import { useParams } from 'react-router-dom';
import axios, {AxiosHeaders} from 'axios';

import {useMeeting} from '../../../contexts/MeetingContext';

import style from './Agenda.module.css';
import WorkingQuestionPopover from '../../../components/Meeting/WorkingQuestionPopover';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';  // theme
import 'primereact/resources/primereact.min.css';           // core css
import './Agenda.css';

import AgendaComponent from '../../../components/Meeting/Agenda/Agenda';

export default function  Agenda(props: {
  meeting: Meeting
  setWorkingQuestions: (workingQuestions: WorkingQuestion[]) => void
  userRole: Role
}) {

  const { dateTimeEnded, workingQuestions } = props.meeting;

  return (
    <>
      <section
        className={style.content}
      >
        <div className={style.topRow}>
          <div></div>
          <h1
            className={style.heading}
          >
            {
              dateTimeEnded ?
                  <span>
                    Your working questions
                  </span>
                :
                <>
                  <span>
                  Enter one or more working questions
                  </span>
                  <WorkingQuestionPopover popoverText='(i)' contentText={'What is a working question?!'} style={{fontSize: '3rem'}}/>
                </>
            }
          </h1>
          <Button className={style.contButton} onClick={() => window.location.href=`/${props.meeting.id}/invitees`}>Contributors</Button>
        </div>
        <AgendaComponent workingQuestions={workingQuestions} dateTimeEnded={dateTimeEnded} setWorkingQuestions={props.setWorkingQuestions} userRole={props.userRole} includeBacklog/>
      </section>
    </>
  )
}
