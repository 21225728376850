import '../styles/learnMore.css';
import UserAgreementLink from '../components/UserAgreementLink';
import Navbar from '../Navbar';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function LearnMore() {
  const { t } = useTranslation();
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [succesSubmit, setSuccesSubmit] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;

    const name = form.elements.namedItem('name') as HTMLInputElement;
    const email = form.elements.namedItem('email') as HTMLInputElement;
    const meetingsFrequency = form.elements.namedItem(
      'meetingsFrequency',
    ) as HTMLInputElement;
    const teamSize = form.elements.namedItem('teamSize') as HTMLInputElement;

    const formResults = {
      name: name.value,
      email: email.value,
      meetingsFrequency: meetingsFrequency.value,
      teamSize: teamSize.value,
    };

    axios
      .get(`${baseURL}api/learn-more`, {
        params: formResults,
      })
      .then(() => {
        setSuccesSubmit(true);
      })
      .catch(() => {
        setSuccesSubmit(false);
        alert('failed to submit form. please try again');
      });
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          color: '#333333',
          textAlign: 'center',
          marginLeft: '20%',
          marginRight: '20%',
          width: '60%',
          fontWeight: '900',
          marginBottom: '70px',
        }}
      >
        <br />
        <p
          style={{
            color: '#333333',
            fontSize: '5.5rem',
            textAlign: 'center',
            marginTop: '0px',
            fontFamily: 'Figtree',
            marginBottom: '35px',
          }}
        >
          {t(
            'How to increase your team performance simply and effectively with qck.app',
          )}{' '}
        </p>
        <a
          style={{
            color: '#333333',
            fontSize: '2rem',
            fontWeight: '500',
            textAlign: 'center',
            marginTop: '0px',
            width: '50%',
            fontFamily: 'Figtree',
          }}
        >
          {t(
            'Learn how qck.app works in an interactive video demo, or sign up for a free trial of qck.app.',
          )}{' '}
        </a>
      </div>

      <div id="container">
        <div
          style={{ width: '250px', backgroundColor: '#016fa0', color: 'white' }}
        >
          <h1>Video yet to come</h1>
        </div>
        {!succesSubmit ? (
          <form id="field" onSubmit={handleSubmit}>
            <a style={{ fontSize: '2.5rem' }}>
              {t('We are looking forward to answer your questions!')}
            </a>
            <p style={{ fontSize: '1.5rem' }}>
              {t(
                'Please leave your data now and we will get in touch with you as soon as possible!',
              )}
            </p>
            <input
              id="name"
              className="field"
              placeholder={t('Your name*') || ''}
              required
              type="text"
              style={{ marginBottom: '10px' }}
            ></input>
            <input
              id="email"
              className="field"
              placeholder={t('Your email contact*') || ''}
              required
              type="email"
              style={{ marginBottom: '10px' }}
            ></input>
            <input
              id="meetingsFrequency"
              className="field"
              placeholder={
                t('What would you like to achieve with your team?') || ''
              }
              type="text"
              style={{ marginBottom: '10px' }}
            ></input>
            <input
              id="teamSize"
              className="field"
              placeholder={
                t('How many people would you like to involve?') || ''
              }
              type="text"
              style={{ marginBottom: '10px' }}
            ></input>
            <input
              type="submit"
              id="submitButton"
              value={t('Get in contact with us!') || ''}
            ></input>
          </form>
        ) : (
          <div id="field">
            <a
              style={{
                fontSize: '2.5rem',
                fontFamily: 'Figtree',
                fontWeight: '500',
                marginBottom: '20px',
              }}
            >
              We will contact you via email as soon as possible
            </a>
            <Link style={{ width: '100%' }} to="/">
              <button style={{ width: '100%' }} id="submitButton">
                Go back to main page
              </button>
            </Link>
          </div>
        )}
      </div>
      <UserAgreementLink />
    </>
  );
}
