import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import '../styles/results.css';
import { useTranslation } from 'react-i18next';
import DotsLineResults from '../components/DotsLineResults';
import SetDurationModal from '../components/SetDurationModal';
import UserAgreementLink from '../components/UserAgreementLink';
import Navbar from '../Navbar';
import { useNavigate } from 'react-router-dom';
import QuestionnaireSettings from '../components/QuestionnaireSettings';
import * as React from 'react';
import TypeIcon from '../components/TypeIcon';

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function Results() {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const loginHint = urlParams.get('loginHint') || '';
  const token = urlParams.get('token') || '';
  //Sets default questaionnare data
  const defaultData = {
    customId: '9999',
    keyStr: 'xxxxxxxxx',
  };

  const { qnrId } = useParams();

  const [qnrType, setQnrType] = useState(0);
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>(defaultData);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  // knows if request to be redirected to login page has been made
  // let redirected = false; 
  //Axios request to server
  const getVotes = () => {
    axios
    .get(baseUrl + 'api/get-results', {
      params: {
        qnrId: qnrId
      },
    })
    .then((response) => {
      const questionnaire = response.data;

      setQuestionnaire(questionnaire);
      if (!questionnaire.duration && questionnaire.type === 1) {
        setModalOpen(true);
      }
    })
    .finally(() => setLoading(false));
  };

  useEffect(getVotes, []);

  return (
    <>
      {modalOpen && (
        <SetDurationModal
          setOpen={setModalOpen}
          open={modalOpen}
          qnrId={qnrId || ''}
          duration={questionnaire.duration}
        />
      )}
      {!loading ? (
        <>
          <Navbar />

          <div className="two-element-flexbox">
            
            <TypeIcon type={qnrType} id = 'distribuIcon'/>
            <h1
              id="results-h1"
              style = {{
                whiteSpace:   'normal',
                width:        '80%',
                overflowWrap: 'break-word'
              }}
            >
              {questionnaire.qnrName || t('Results')}
            </h1>
            <div style={{ width: '7em' }}></div>
          </div>

          <QuestionnaireSettings
            customId={qnrId || ''}        
          />          
          {questionnaire.votes && questionnaire.votes.length ? (
           <DotsLineResults name={questionnaire.qnrName || t('Results')} type={questionnaire.type} votes={questionnaire.votes} />
          ) : <></>}
         <UserAgreementLink />
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  );
}
