import UserAgreementLink from '../components/UserAgreementLink';
import Navbar from '../Navbar';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/enterAccount.css';
import { v4 as uuid } from 'uuid';

export default function EnterAccount() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const { emailParam } = useParams();
  const { keyParam } = useParams();
  const inTeams = !!(new URLSearchParams(window.location.search).get('inTeams'));
  const keepLoggedIn = localStorage.getItem('keepLoggedIn') === 'true';

  const [logged, setLogged] = useState('waiting');
  let redirected = false;

  const sendKeyAndEmail = () => {
    let params : {
      email: string,
      key: string,
      keepLoggedIn: boolean,
      teams?: boolean
    } = { email: emailParam || '', key: keyParam || '', keepLoggedIn } 

    if(inTeams){
      params = {
        ...params,
        teams: inTeams
      }
    }
    axios
    .get('/api/enter-account', {
      params: params,
    })
    .then((response) => {
      if (response.data.message) {
        setLogged('succesfull');
        backToOriginalDestination();
      } else {
        setLogged('failed');
      }
    })
    .catch(() => {
      console.log('error');
      setLogged('failed');
    });
    localStorage.removeItem('keepLoggedIn');
  };

  useEffect(sendKeyAndEmail, [emailParam, keyParam]);


  const backToOriginalDestination = () => {
    if(redirected) return;
    redirected = true;
    const afterLogInLocation = localStorage.getItem('afterLogInLocation');
    console.log('afterloginlocatin: ', afterLogInLocation)
    localStorage.removeItem('afterLogInLocation')
    console.log(redirected)
    if (afterLogInLocation === baseURL) {
      navigate('/choose-qnr-type');
      return
    }
    if (afterLogInLocation === baseURL + 'login') {
      navigate('/');
      return
    }

    window.location.href =
      afterLogInLocation || '/';
  };

  return (
    <>
      <Navbar />
      <div style={{ textAlign: 'center' }}>
        {logged === 'waiting' ? (
          <>
            <h1>Waiting...</h1>
          </>
        ) : (
          <>
            {logged === 'succesfull' && <h1>Succesfully logged in</h1>}
            {logged === 'failed' && <h1>Failed to log in</h1>}
          </>
        )}

        <UserAgreementLink />
      </div>
    </>
  );
}
