import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import style from './Contributors.module.css'
import Agenda from '../../../components/Meeting/Agenda/Agenda';

import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

export default function Contributors(props: {
  workingQuestions: WorkingQuestion[]
  userRole: Role
  hasStarted?: boolean
  dateTimeEnded?: number
}){
  const { id } = useParams();

  return (
    <>
      {props.hasStarted ? 
        <div className={style.hasStarted}>
          <p>
            {'You can\'t modify the agenda after the meeting has started!'}
          </p>
          <Button
            style={{
              fontSize: '2rem'
            }}
            onClick={() => window.location.href=`/p/${id}`}
          >Back to meeting</Button>
        </div>
        :
      <div className={style.container}>
        <h1>From here you can modify the working questions</h1>
        <h2>and in this way contribute to the Agenda</h2>
        <div className={style.backToMeetingContainer}>
          <Button
            style={{
              fontSize: '2rem'
            }}
            onClick={() => window.location.href=`/p/${id}`}
          >Back to meeting</Button>
        </div>
        <Agenda workingQuestions={props.workingQuestions} dateTimeEnded={props.dateTimeEnded} userRole={props.userRole}/>
      </div>}
    </>
  )
}
