import * as React from 'react'
import axios from 'axios';
import * as microsoftTeams from '@microsoft/teams-js'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

export default function TeamsVerifyView(){

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const UNCACHABLE_PAGES = [
    'how-to'
  ]

  const navigate = useNavigate()

  const navToOther = (page: any) => {

    if(page === ``){
      navigate(`/`)
      return
    }
    
    if(page === 'authenticate'){
      navigate('/authenticate')
      return
    }

    if(page === 'learn-more'){
      navigate(`/learn-more`)  
      return
    }
    console.log(`redirecting to /ms-teams/${page}`)
    window.location.href = `/ms-teams/${page}`
  }
  
  const setPageAsLastPage = () => { 
    console.log("BEGIN OF CACHING PAGE");
    
    const location = window.location.href;
    
    console.log(`CACHING PAGE - CURRENT PAGE: ${location}`)
    console.log(`CACHING PAGE - BEGIN CHECK IF PAGE IS UNCACHABLE`);
    
    let isCurrentPageCachable = true;

    UNCACHABLE_PAGES.forEach(page => {
      console.log(`CACHING PAGE - CHECK FOR PAGE "${page}" RESULT: "${location.includes(page)}" `);
      if(location.includes(page)){
        isCurrentPageCachable = false;
        return; 
      }
    });
    
    if(isCurrentPageCachable){

      const cache = {
        last_page : location,
        when_created : Date.now()
      }
      
      localStorage.setItem('last-page', JSON.stringify(cache)); 
      console.log(`CACHING PAGE - CACHED ${location} SUCCESFULLY`);
      return
    }

    console.log(`CACHING PAGE - FAIL: RETURNING`);
  }
  
  const onLoad = () => {
    const func = async () => {
      // request to teams-get-location to tell me where i should be
      // if there - do nothing; if not - redirect.

      await microsoftTeams.app.initialize()
      
      const context = await microsoftTeams.app.getContext()
      
      try{
        await axios
        .post(`${baseUrl}api/teams-get-location`,{
            teamsContext: context
          })
          .then((response) => {
            console.log(response.data)
            const pageToRedir = response.data.redirectUrl
            const currentPage = window.location.pathname
            console.log(`IMPORTANT INFO:\n/////\n${pageToRedir}\n${currentPage}\n/ ////`)
          
            if(currentPage.includes(pageToRedir) || currentPage === pageToRedir){
              setPageAsLastPage()
              return
            }

            navToOther(`${response.data.redirectUrl}`) 
          })
        }catch(e){
          navToOther(``)
        } 
      }
    func()
  }
  
  useEffect(onLoad, [])
    

  return (
    <>

    </>
  )
}
