import QRCode from 'react-qr-code';
import { useLocation, useParams } from 'react-router-dom';
import { ReactComponent as CopySVG } from '../images/copy.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import '../styles/create.css';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import SetName from '../components/SetNameForm/SetName';
import UserAgreementLink from '../components/UserAgreementLink';
import Navbar from '../Navbar';
import QuestionnaireLinks from '../components/QuestionnaireLinks/QuestionnaireLinks';
import * as React from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL || '';

export default function Create() {

  const { qnrId } = useParams()

  const { t } = useTranslation();


  const linksWrapperRef = useRef<HTMLDivElement>(null);
  const scrollLinksIntoView = () => {

    if(linksWrapperRef.current){
      linksWrapperRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  type displayStyle = 'none' | 'block' | 'flex';
  const [copyAlertDisplay, setCopyAlertDisplay] =
    useState<displayStyle>('none');

  const [visible, setVisible] = useState(false);
  const [type, setType] = useState('');
  const [moto, setMoto] = useState('');

  const [bulletPoints, setBulletPoints] = useState<string[]>([]);

  useEffect(() => {
    if(visible){
      scrollLinksIntoView();
    }
  }, [visible])
  useEffect(() => {
    axios
      .get(`${baseURL}api/get-qnr-type`, {
        params: { qnrId: '' + qnrId },
      })
      .then((response) => {
        response.data.message === 1 &&
          (setType(t('Start Meeting Follow-Up') || ''),
          setMoto(
            t(
              'Ensure improved results and a continuous increase in efficiency in your meetings!',
            ) || '',
          ));
        response.data.message === 3 &&
          (setType(t('Start Team Check-In') || ''),
          setMoto(
            t(
              'Boost the impact and cohesion of your team for sustainable success!',
            ) || '',
          ),
          setBulletPoints([
            t(
              'Send out the invitation to answer the questions before your team meeting in order to be able to derive targeted strengthening measures on the basis of the answers.',
            ),
            t(
              'Answering the questions only takes a few minutes, reserve about an hour for the meeting afterwards.',
            ),
            t(
              'Due to the quick response, you can also start the survey in the team meeting itself.',
            ),
          ]));
        response.data.message === 4 &&
          (setType(t('Start Mission Statement Check') || ''),
          setMoto(t('Bring more momentum into your organisation!') || ''),
          setBulletPoints([
            t(
              'With only 4 questions, you can check essential success factors for companies, based on a proven action model.',
            ),
            t(
              'Answering the questions takes only a few minutes, reserve about an hour for the meeting afterwards.',
            ),
            t(
              'You can also start the survey in the team meeting itself because of the quick answers!',
            ),
          ]));
        response.data.message === 2 &&
          (setType(t('Start Change Communication Survey') || ''),
          setMoto(t('Support your change with impactful communication!') || ''),
          setBulletPoints([
            t(
              'Send out the invitation to answer the questions before your team meeting so that you can derive targeted supportive measures for your change communication.',
            ),
            t(
              'Answering the questions takes only a few minutes; reserve about an hour for the meeting itself.',
            ),
            t(
              'Due to the quick response, you can also start the survey in the team meeting itself!',
            ),
          ]));
      })
  }, []);

  return (
    <>
      {type !== '' ? (
        <>
          <Navbar />
          <div id="headers">
            <a
              style={{
                fontSize: '6rem',
                width: '100%',
                fontWeight: '900',
                paddingBottom: '3.5rem',
              }}
            >
              {type + '!'}
            </a>
            <a
              style={{
                fontSize: '2.7rem',
                width: '70%',
                fontWeight: '300',
                paddingBottom: '6rem',
              }}
            >
              {moto}
            </a>
            <br />
            <ul
              style={{
                display: bulletPoints.length > 0 ? 'flex': 'none',
                justifyContent: 'center',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {bulletPoints.map((item, index) => (
                <li
                  style={{
                    fontSize: '2rem',
                    width: '70%',
                    fontWeight: '400',
                    paddingBottom: '1rem',
                    textAlign: 'left',
                  }}
                  key={index}
                >
                  {item}
                </li>
              ))}
            </ul>
            <a
              style={{
                fontSize: '3rem',
                width: '70%',
                fontWeight: '900',
                paddingBottom: '1rem',
              }}
              id="create-h1"
            >
              {type === t('Start Meeting Follow-Up') &&
                t('What is the working question* of this meeting?')}
            </a>
          </div>
          <SetName
            qnrId={qnrId ? qnrId : 'Missing qnrID'}
            setVisible={setVisible}
            qnrType={type}
          />
          {type === t('Start Meeting Follow-Up') && (
            <div style={{ paddingTop: '40px' }}>
              <a
                id="warning-msg"
                style={{
                  display: 'block',
                  marginBlockStart: '1em',
                  marginBlockEnd: '1em',
                  padding: '5px',
                  width: '60%',
                  minWidth: '30rem',
                  fontSize: '1.8rem',
                  borderColor: 'gray',
                  borderRadius: '5px',
                }}
              >
                {t(
                  '* If you want to know more about working questions, click ',
                )}
                <a
                  href="https://www.youtube.com/watch?v=QbNPOTR_pKk"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline', color: '#016fa0' }}
                >
                  {t('here')}
                </a>
              </a>
            </div>
          )}
          
          <section
            style={{
              display: 'flex',
              width: '98%',
              maxWidth: '500px',
              margin: '0 auto'
            }}
          >
            <QuestionnaireLinks
              linksWrapperRef={linksWrapperRef}
              visible={visible}
              qnrIdParam={qnrId || ''}
              setCopyAlertDisplay={setCopyAlertDisplay}
            />
          </section>
          <Alert
            id="copy-alert"
            icon={
              <CheckCircleOutlineIcon
                style={{ color: 'black' }}
                fontSize="inherit"
              />
            }
            style={{ display: copyAlertDisplay }}
            severity="info"
            onClose={() => {
              setCopyAlertDisplay('none');
            }}
          >
            Item copied to clipboard successfuly
          </Alert>
          <UserAgreementLink />{' '}
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  );
}
