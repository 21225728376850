import * as React from "react";
import {useParams} from "react-router-dom";
import {useMeeting} from "../../../contexts/MeetingContext";
import Evaluation from "../../../components/Meeting/Evaluation";

export default function EvaluationHostPage(props:{
  meeting: Meeting
}) {

  const { dateTimeStarted, dateTimeEnded, invitees, workingQuestions, feedback, type, workingQuestionMinutes} = props.meeting;

  return (
    <>
      <Evaluation
        dateTimeEnded={dateTimeEnded}
        dateTimeStarted={dateTimeStarted}
        inviteesList={invitees}
        workingQuestions={workingQuestions}
        feedback={feedback}
        type={type}
        minutes={workingQuestionMinutes}
      />
    </>
  )
}



