import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import {MAX_QUESTION_ANSWER, TYPE_QUESTIONS_MAP} from "../../constants";

export default function EvaluationGraph(props: {
  feedback: {
    answers: Answer;
    opinions: Opinion;
    votingStartedDateTime?: number;
    votingEndedDateTime?: number;
  }
  type: MeetingType
}) {
  
  const { feedback, type } = props;

  const [ averageVoteValues, setAverageVoteValues ] = React.useState<number[]>([]);

  const calcAverageVoteValues = () => {
    type ValueCount = {count: number; value: number};
    const feedbackQuestionCount = TYPE_QUESTIONS_MAP[type].length;
    const valueCountList: ValueCount[] = [];
    for(let i = 0; i < feedbackQuestionCount; i ++){
      valueCountList.push(new Object({count: 0, value: 0}) as ValueCount);
    }

    for(const email in feedback.answers){
      const answers = feedback.answers[email];
      for(let qIndex = 0; qIndex < feedbackQuestionCount; qIndex ++){
        valueCountList[qIndex].count ++;
        valueCountList[qIndex].value += answers[qIndex];
      }
    }

    setAverageVoteValues(valueCountList.map((vc) => {
      return Math.floor(vc.value / vc.count);
    }));
  }

  React.useEffect(calcAverageVoteValues, [feedback]);

  return (
    <>
      <BarChart
        series={[
          { data: averageVoteValues },
        ]}
        height={290}
        xAxis={[{ data: ['Q1','Q2','Q3','Q4'], scaleType: 'band' }]}
        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
      />
    </>
  )
}
