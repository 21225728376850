import * as React from 'react';

import '@mdxeditor/editor/style.css';

import { useParams } from 'react-router-dom';
import style from './Evaluation.module.css';
import {Button} from "@mui/material";
import parseNumber from '../../functions/parseNumber';
import {MAX_QUESTION_ANSWER, TYPE_QUESTIONS_MAP} from "../../constants";
import EvaluationGraph from "./EvaluationGraph";
import Results from "./Voting/Results/Results";

import gfm from 'remark-gfm';
import CustomMarkdownRenderer from '../CustomMarkdownRenderer';

export default function Evaluation(props: {
 dateTimeStarted?: number
 dateTimeEnded?: number
 inviteesList: Invitee[]
 workingQuestions: WorkingQuestion[]
 feedback: Feedback
 type: MeetingType
 minutes: Minute[]
}) {

  const { id } = useParams();
  const { dateTimeStarted, dateTimeEnded, inviteesList, workingQuestions, feedback, type, minutes} = props;

  const [ totalVotes, setTotalVotes ] = React.useState<number>();

  const [ wqOnDisplay, setWqOnDisplay ] = React.useState<number>(-1);

  const asd: Record<string, string> = {
    'asd': 'asd'
  }

  const changeOnDisplay = (newOnDisplay: number) => setWqOnDisplay((curr) => newOnDisplay === curr ? -1 : newOnDisplay); 

  const formatHourMinute = (time: number) => {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return parseNumber(hours) + ':' + parseNumber(minutes);
  }

  const formatMinuteSeconds = (time: number) => {
    const seconds = Math.floor(time / 1000) % 60;
    const minutes = (Math.floor(time / 1000) - seconds) / 60;
    return parseNumber(minutes) + ':' + parseNumber(seconds);
  }


  const calcTotalVotes = () => {
    let total = 0;
    for(const wqIndex of Object.keys(feedback)){
      total += Object.keys(feedback[wqIndex as unknown as number].answers).length;
    }
    setTotalVotes(total);
  }

  React.useEffect(() => {
    calcTotalVotes();
  }, [feedback]);

  return (
    <>
      {dateTimeStarted && !dateTimeEnded ? 
        <div className={style.beforeEndContainer}>
          <p className={style.beforeEndCaption}>
            This Qck is currently in progress
          </p>
          <Button
            variant="contained"
            href={`/${id}/start`}
            className={style.beforeEndButton}
          >Go to meeting</Button>
        </div>:<></>
      }
      {!dateTimeEnded && ! dateTimeStarted ?
        <div className={style.beforeEndContainer}>
          <p className={style.beforeEndCaption}>
            Here you can see the evaluation of the Qck after its completion
          </p>
          <Button
            variant="contained"
            href={`/${id}/meeting`}
            className={style.beforeEndButton}
          >Start Qck</Button>
        </div>:<></>
      }
      {dateTimeEnded && dateTimeStarted ?
        <div className={style.content}>
          <h1 className={style.heading1}>
            Here is how the Qck went
          </h1>
          <ul className={style.durationList}>
            <li>
              Start <br/>
              {formatHourMinute(dateTimeStarted)}
            </li>
            <li>
              End <br/>
              {formatHourMinute(dateTimeEnded)}
            </li>
            <li>
              Duration <br/>
              {formatMinuteSeconds(Math.abs(dateTimeStarted - dateTimeEnded))}
            </li>
          </ul>

          <ul className={style.basicDataList}>
            <li>
              <p>total participants</p>
              <span>{inviteesList.length}</span>
            </li>
            <li>
              <p>working questions</p>
              <span>{workingQuestions.length}</span>
            </li>
            <li>
              <p>total votes</p>
              <span>{totalVotes}</span>
            </li>
          </ul>


          <h1 className={`${style.heading1} ${style.headingWorkingQuestions}`}>
            And here is all the feedback gathered per working question
          </h1>
          <ul className={style.workingQuestionsList}>
            {workingQuestions.map((wq, wqIndex) => { 
              if(!feedback[wqIndex]) return;
              const votersCount = Object.keys(feedback[wqIndex].answers).length;
              const isOnDisplay = wqOnDisplay === wqIndex;
              return (
                <li key={wqIndex}>
                  <div className={style.workingQuestionOverview} onClick={() => changeOnDisplay(wqIndex)}>
                    <span className={style.workingQuestionText}>{wq.text}</span>
                    <span
                      className={style.workingQuestionVoters}
                    >
                      {votersCount} {votersCount === 1 ? 'person' : 'people'} answered
                    </span>
                  </div>
                  <section className={`${style.workingQuestionEvalContainer} ${ isOnDisplay ? style.show : style.hide}`}>
                    <p className={style.discussionStarted}>Started discussion at {formatHourMinute(workingQuestions[wqIndex].startDateTime || 0)}</p>
                    
                    <div className={style.notesContainer}>
                      <h2 className={style.notesHeading}>
                        Notes:
                      </h2>

                      <CustomMarkdownRenderer className={style.notes} remarkPlugins={[gfm]}>
                        {minutes[wqIndex].text || 'There are no notes'}
                      </CustomMarkdownRenderer>

                    </div>
                    <div className={style.avgVotesGraphContainer}>
                      <h1 className={style.avgVotesHeading}>
                        Avarage vote per feedback question
                      </h1>
                      <EvaluationGraph type={type} feedback={feedback[wqIndex]} />
                    </div>

                    <Results feedback={feedback[wqIndex]} type={type} emailColorMap={Object.fromEntries(inviteesList.map(({email, color}) => [email, color]))}/>
                  </section>
                </li>
              )
            })}
          </ul>

        </div>
        :<></>
      }
    </>
  )
}
