import CountCnt from './CountCnt';
import '../../styles/RegularMeeting.css'
import '../../styles/RegularMeetingEmailInput.css'
import * as React from 'react';
import { RegularMeetingOverviewNumbers } from './types';
import axios from 'axios';
import RegularMeetingEmail from '../../components/RegularMeetingEmail';
import GIFLoading from '../../components/GIFLoading';
import {ReactComponent as IconArrowDown} from '../../images/Icon-arrow-down.svg'
import RegularMeetingEmailInput from '../../components/RegularMeetingEmailInput';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import VotesDataComponent from '../../components/VotesDataComponent';

export default function Overview(props: {
  regularMeeting: RegularMeetingView,
  setRegularMeeting: React.Dispatch<React.SetStateAction<RegularMeetingView | undefined>>
}){
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();

  const [ regularMeetingOverviewNumbers, setRegularMeetingOverviewNumbers ] = React.useState<RegularMeetingOverviewNumbers | undefined>();
  const [ showEmails, setShowEmails ] = React.useState(false);
  const [ showVotes, setShowVotes ] = React.useState(false);
  const [ totalVotes, setTotalVotes ] = React.useState<number | undefined>();
  const [ emailInvalidInput, setEmailInvalidInput] = React.useState<string>('');
  const [ avgVotes, setAvgVotes ] = React.useState<{
    total: number,
    count: number
  }[]>([]);

  const [ started, setStarted ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(!props.regularMeeting) return;

    const totalQuestionnaires = props.regularMeeting.questionnaires.length;
    const msInDay =  86400000;

    let timeSinceLastQnr = 2000000000;
    let totalQuestionnaireVotes = 0;
    let totalTimeBetweenQnrs = 0;

    const votes : {
        total: number,
        count: number
    }[] = []

    totalQuestionnaires !== 0 && props.regularMeeting.questionnaires.forEach((qnr, index) => {
      // const voteCounts : number[] = [];
      // const voteNumbers : number[] = [];
      
      
      
      qnr.votes?.forEach((vote) => {
        vote.questions.forEach((question, index) => {
          if(!votes[index]){
            votes[index] = {
              total: 0,
              count: 0
            };
          }
          votes[index].count ++;
          if(question.value > 0)
            votes[index].total += question.value;
        })
      })


      totalQuestionnaireVotes += qnr.votes?.length || 0;
      if(index !== props.regularMeeting.questionnaires.length - 1){
        const timeFromNextQnr = Math.abs(new Date(qnr.dateCreated || '').getTime() - new Date(props.regularMeeting.questionnaires[index + 1].dateCreated || '').getTime());
        totalTimeBetweenQnrs += timeFromNextQnr;
      }
      const date = new Date(qnr.dateCreated || '');
      if(date.getTime() > timeSinceLastQnr){
        timeSinceLastQnr = date.getTime()
      }
    })
    
    for(const vote of votes){
      vote.total = Math.floor(vote.total / vote.count);
    }

    setAvgVotes(votes);
    const daysSinceLastQnr = totalQuestionnaireVotes ? Math.floor((new Date().getTime() - timeSinceLastQnr) /  msInDay) : 0;
    const avgTimeBetweenQnrs = Math.round((totalTimeBetweenQnrs /  msInDay) / props.regularMeeting.questionnaires.length);

    const data : RegularMeetingOverviewNumbers= {
      timeSinceLastQnr: {
        time: daysSinceLastQnr,
        unit: daysSinceLastQnr === 1 ? 'Day' : 'Days'
      },
      avgTimeBetweenQnrs: {
        time: avgTimeBetweenQnrs,
        unit: avgTimeBetweenQnrs === 1 ? 'Day' : 'Days'
      },
      totalQnrs: {
        count: totalQuestionnaires
      },
      totalVotes: {
        count: totalQuestionnaireVotes
      }
    }

    setRegularMeetingOverviewNumbers(data);

  }, [props.regularMeeting])

  const setEmailsList = (newEmailsList : string[]) => {
    if(!props.regularMeeting) return;
    if(newEmailsList.length > props.regularMeeting?.participantEmails.length){
      const url = `${baseUrl}api/add-email-regular-meeting`
      axios
      .put(url, {
        newEmail: newEmailsList[newEmailsList.length - 1],
        customId: props.regularMeeting?.customId
      })
    }else{
      const url = `${baseUrl}api/remove-email-regular-meeting`
      axios
      .put(url, {
        newEmailsList : newEmailsList,
        customId: props.regularMeeting.customId
      })
    }
    
    props.setRegularMeeting({
      ...props.regularMeeting,
      participantEmails : newEmailsList
    })
  }

  const startRegularMeeting = () => {

    const customId = Math.floor(100000 + Math.random() * 900000).toString();
    const qnrStr = uuid().slice(0, 12);
    
    const url = `${baseUrl}api/start-regular-meeting`

    axios
    .post(url, {
      customId: customId,
      qnrStr: qnrStr,
      regularMeetingId: props.regularMeeting.customId
    }).then( (result) => {
      window.location.href = `/dashboard/questionnaire/${customId}`
    })
    
    setStarted(true);
  }

  return (
    <section
      id = 'overview-inner-cnt'
    >
      <div
        className='overview-counts-cnt'
      >

        <div
          className='overview-counts-aligning-div'
        >
          <CountCnt
            property='Between questionnaires on average'
            count={regularMeetingOverviewNumbers?.avgTimeBetweenQnrs.time || 0}
            unit={regularMeetingOverviewNumbers?.avgTimeBetweenQnrs.unit  || ''}
          />
          <CountCnt
            property='Questionnaires created'
            count={regularMeetingOverviewNumbers?.totalQnrs.count || 0}
          />
        </div>

        <div
          className='overview-counts-aligning-div'
        >
          <CountCnt
            property='Since last questionnaire'
            count={regularMeetingOverviewNumbers?.timeSinceLastQnr.time || 0}
            unit={regularMeetingOverviewNumbers?.timeSinceLastQnr.unit || ''}
          />
          <CountCnt
            property='Votes on all questionnaires'
            count={regularMeetingOverviewNumbers?.totalVotes.count || 0}                          
          />
        </div>
      </div>
      <section
        id = 'rm-content-cnt'
      >
        <section
          id = 'btn-emails-cnt'
        >
          <p
            className = 'show-contents-text'
          >
            {`${t('Number of participants')}: ${props.regularMeeting.participantEmails.length}`}
          </p>
          <button
            id = 'btn-show-emails'
            onClick={ () => {
              setShowEmails(!showEmails);
            }}
          >

            <span 
              className = 'show-arrow-cnt'
            >
              <span
                className = 'show-text-cnt'
              >
                {showEmails ? t('Hide') : t('Show')}
              </span>
              <IconArrowDown  className={`show-arrow ${showEmails ? 'flip' : ''}`} />
            </span>
          </button>
          <section
            id = 'emails-emails-cnt'                            
            className={showEmails ? 'visible' : ''}
          >
            <section
              id = 'participants-list-emails'
            >
              {
                !props.regularMeeting.participantEmails || props.regularMeeting.participantEmails.length <= 0? 
                  <span
                    id = 'no-emails'
                  >
                    {t("You haven't listed any emails yet! ")}
                  </span>
                :
                props.regularMeeting.participantEmails.map((email, index) => (
                    <RegularMeetingEmail 
                      email       = {email}
                      index       = {index}
                      key         = {index}
                      removeEmail = {(emailToRemove: string) => {
                          setEmailInvalidInput('');
                          const newList = [...props.regularMeeting.participantEmails];
                          const indexToRemove = newList.indexOf(emailToRemove);
                          newList.splice(indexToRemove, 1)
                          setEmailsList(newList);
                      }}
                    />
                  ))

              }
            </section> {/* participants-list-emails */}
            
            
            <RegularMeetingEmailInput 
              customId={props.regularMeeting.customId}
              emailsList={props.regularMeeting.participantEmails}
              setEmailsList={setEmailsList}
              invalidText={emailInvalidInput}
              setInvalidText={setEmailInvalidInput}
              placeholderText='Enter new email here'
            />
          </section> {/* emails-emails-cnt */}
        </section> {/* btn-emails-cnt */}
        <section
            id = 'votes-cnt'
        >
            <p
                className='show-contents-text'
            >
                {t('Votes')}: {regularMeetingOverviewNumbers?.totalVotes.count}
            </p>
            
            <button
                id = 'votes-show-btn'
                onClick = {() => {
                    setShowVotes((curr) => {
                      return !curr;
                    });
                }}
            >
                <span 
                  className = 'show-arrow-cnt'
                >
                  <span
                    className = 'show-text-cnt'
                  >
                    {showVotes ? t('Hide') : t('Show')}
                  </span>
                  <IconArrowDown className={`show-arrow ${showVotes ? 'flip' : ''}`} />
                </span>

            </button>
            
            <VotesDataComponent
              showVotes={showVotes}
              regularMeeting={props.regularMeeting} 
            />

          
        </section> {/* votes-cnt */}
      </section> {/* rm-content-cnt */}
        <div
          id = 'start-btn-div'
        >
          <div
            className='margin-div'
          >

          </div>
          <button
            onClick={startRegularMeeting}
          >
            <div
              id = 'gif-loading-cnt'
            >
              <GIFLoading          
                show = {started}
              />
            </div>
            {t('START')}
          </button>            
        </div>
    </section> //overview-inner-cnt
  )
}
