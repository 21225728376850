import {OrderOperation} from "../enums";

/**
  * Doesn't modify original arr
  */
export default function reorder(arr: any[], index: number, op: OrderOperation): [any[], number?]{
    if(
      (index === 0 && op === OrderOperation.UP)
      || (index === 0 && op === OrderOperation.START)
      || (index === arr.length - 1 && op ===OrderOperation.DOWN)
      || (index === arr.length - 1 && op ===OrderOperation.END)
    ){
      return [ [...arr], index ];
    }

    const new_arr = [...arr];

    const swap = (i1: number, i2: number) => {
      [new_arr[i1], new_arr[i2]] = 
        [new_arr[i2], new_arr[i1]];
    }

    let new_index = -1;
    
    switch(op){
      case OrderOperation.UP: swap(index, index-1); new_index = index-1; break;
      case OrderOperation.DOWN: swap(index, index+1); new_index = index+1; break;
      case OrderOperation.START: 
        const [item] = new_arr.splice(index, 1);
        new_arr.unshift(item);
        new_index = 0;
        break;
      case OrderOperation.END: 
        const [item1] = new_arr.splice(index, 1);
        new_arr.push(item1);
        new_index = new_arr.length - 1;
        break;
    }

    
    return [ new_arr, new_index ];
}
