import { Link } from 'react-router-dom';
import './styles/navbar.css';
import { ReactComponent as QckLogo } from './images/qck-logo66x76.svg';
import Languages from './components/Languages';
import * as React from 'react';
import MyAccount from './components/MyAccount';

function Navbar(props: {
  theme?: string
}) {
  return (
    <nav>
       <ul id="navbar">
        <li>
          <a
           href= '/'
           id = 'home-nav'
          >
            <QckLogo
              id="results-logo"
              className="qck-app-corner-logo" 
            />
          </a>
        </li>
        <li
          className='nav-right-cnt'
          style={{display: 'flex' }}
        >
          <MyAccount />
          <Languages theme={props.theme}/>
        </li>
      </ul>
    </nav>
  );
}
export default Navbar;
