import {NavLink} from 'react-router-dom';
import '../styles/DashboardNav.css'
import * as React from 'react';

export default function DashboardNav(){

  return (
    <section
      id = 'dashboard-nav'
    >
      <NavLink
        className={state => {
          return "nav-el " + (state.isActive ? "selected" : "")
        }}
        to='regular-meetings'
      >
        regular meetings
      </NavLink>

      <NavLink
        to='questionnaires'
        className={state => {
          return "nav-el " + (state.isActive ? "selected" : "")
        }}
      >
        questionnaire history 
      </NavLink>

    </section>
  ) 
}
