import * as React from "react";

import style from './Methodology.module.css';

//MUI Barchart
import { BarChart } from '@mui/x-charts/BarChart';
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

export default function Methodology() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      <section
        className={style.content}
      >
        <section
          className={style.diagramWrapper}
        >
          <h1
            className={style.diagramHeading}
          >
            Hello
          </h1>
          <div
            className={style.diagramContainer}
          >
            <BarChart
              series={[
                { data: [35] },
                { data: [51] },
                { data: [15] },
                { data: [100] },
              ]}
              height={290}
              xAxis={[{ data: ['Q1'], scaleType: 'band' }]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            />
          </div>
        </section>
        <div
          className={style.bulletpointsContainer}
        >
          <h1
            className={style.bpHeading}
          >
            Bulletpoints
          </h1>
          <h2
            className={style.bpHeading2}
          >
            to be followed during the meeting
          </h2>
          <ul
            className={style.bpList}
          >
            
            <li
              className={style.bp}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i
            </li>
            <li
              className={style.bp}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i
            </li>
            <li
              className={style.bp}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i
            </li>
            <li
              className={style.bp}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i
            </li>
            <li
              className={style.bp}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i
            </li>
          </ul>
        </div>
      </section>
      <div
        className={style.continueButtonWrapper}
      >
        <Button
          className={style.continueButton}
          onClick={() => navigate(`/${id}/agenda`)}
        >
          {('Continue')}
        </Button>
      </div>
    </>
  )
}


