import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';

function MyAccount() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    axios
    .get('/api/get-account-name')
    .then((response) => {
      setName(response.data.message);
      sessionStorage.setItem('qckEmail', response.data.message);
    })
    .catch((e) => {
      console.log(e)
    }).finally(() => {
      setLoading(false);
    })

  }, []);

  return (
    <>
      {!loading &&
        <a
          href={name ? '/about-my-account/' + name : '/login'}
          id = 'nav-account-link'
        >
          {
            name || 'Login'
          }
        </a>
      }
    </>
  );
}
export default MyAccount;
