import * as React from 'react';
import style from './EndQckWarning.module.css';

export default function EndQckWarning(props: {
  shouldShowWarning: boolean
  cancel: () => void
  endQck: () => void
}) {
  return (
    <div className={style.endWarningOverlay} style={{display: props.shouldShowWarning ? 'grid': 'none'}}>
      <div className={style.endWarningContainer}>
        <h1 className={style.endWarningHeading1} >
          Warning
        </h1>
        <h2 className={style.endWarningHeading2} >
          Are you sure you want to end the Qck before gathering feedback for all working questions?
        </h2>
        <div className={style.endWarningButtons}>
          <button className={`${style.endCancel} ${style.endWarningButton}`} onClick={props.cancel}>
            Cancel
          </button>
          <button className={`${style.endProceed} ${style.endWarningButton}`} onClick={props.endQck}>
            Proceed
          </button>
        </div>
      </div>
    </div>
  )
}
