import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import * as React from 'react';

export default function UserAgreement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <div id="user-licence-page-wrapper">
        <h2>{t('End-User License Agreement')}</h2>
        <p>
          <b>{t('Last updated: 1st February')}</b>
        </p>
        <p>
          {t(
            'This End-User License Agreement (referred to as the "EULA") is a legally binding agreement between you, the Licensee, an individual customer or entity, and the Syntegra KG and Aux Code OOD, the company, and the author of Qck.app, the Software, which may include associated media, printed materials, and online or electronic documentation. This Agreement is a legally binding contract that includes terms that limit your legal rights and Licensors\' liability to you, and shall govern all access to and use of this Software. You hereby agree, without limitation or alteration, to all the terms and conditions contained herein.',
          )}
        </p>
        <p>
          {t(
            'By installing, copying, or otherwise using the Licensed Product (Software), the Licensee agrees to be bound by the terms and conditions outlined in this EULA. However, if the Licensee does not agree to the terms and conditions outlined in this EULA, the said Licensee may not download, install, or use Software.',
          )}
        </p>
        <h3 className="user-agreement-sub-title">{t('Definitions')}</h3>
        <ul className="user-licence-list-style-none">
          <li>
            {t(
              '"EULA" shall refer to this End-User-License-Agreement, including any amendment to this Agreement.',
            )}
          </li>
          <li>
            {t(
              '"Licensee" shall refer to the individual or entity that downloads and uses the Software.',
            )}
          </li>
          <li>
            {t(
              '"Licensor" shall refer to the company or author, Syntegra KG and Aux Code OOD, located at 1040 Vienna, Brahmsplatz 4/19.',
            )}
          </li>
          <li>
            {t(
              '"Software/Licensed product" shall mean Qck.app, the Licensed Product provided pursuant to this EULA.',
            )}
          </li>
        </ul>
        <h3 className="user-agreement-sub-title">{t('Grant of License')}</h3>
        <p>
          {t(
            'Subject to the terms of this EULA, the Syntegra KG and Aux Code OOD hereby grants to the Licensee, a royalty-free, revocable, limited, non-exclusive license during the term of this EULA to possess and to use a copy of the Software. The Software is being distributed by Syntegra KG and Aux Code OOD. Licensee is not allowed to make a charge for distributing this Software, either for profit or merely to recover media and distribution costs.',
          )}
        </p>
        <h3 className="user-agreement-sub-title">
          {t('Intellectual Property')}
        </h3>
        <p>
          {t(
            'You hereby unconditionally agree that all right, title and interest in the copyrights and other intellectual property rights in the Licensed Product reside with the Licensors. The trademarks, logos, designs, and service marks appearing on the Licensed Product are registered and unregistered marks of Licensor. Accordingly, nothing in this EULA or the Licensed Product grants you any right to use any form of intellectual property contained in the Licensed Product.',
          )}
        </p>
        <p>
          {t(
            'Therefore, all rights, titles, interests, and copyrights in and/or to the Software, including but not limited to all images, graphics, animations, audio, video, music, text, data, code, algorithm, and information, are owned by the Licensor. Accordingly, the Software is protected by all applicable copyright laws and international treaties, and the Licensee is expected to use the Software concerning all intellectual property contained therein, except as otherwise provided for in this EULA.',
          )}
        </p>
        <h3 className="user-agreement-sub-title">
          {t('Description of Rights and Limitations')}
        </h3>
        <p>
          <strong>{t('Installation and Use:')}</strong>
          {t(
            " Licensee may install and use the Software on a shared computer or concurrently on different computers, and make multiple back-up copies of the Software, solely for Licensee's use within Licensee's business or personal use.",
          )}{' '}
        </p>
        <p>
          <strong>{t('Reproduction and Distribution:')}</strong>
          {t(
            ' Licensee may not duplicate or re-distribute copies of the Software, without the Licensors express written permission.',
          )}
        </p>
        <p>
          <strong>{t('Licensee Limitation:')}</strong>{' '}
          {t('The Licensee may not:')}
          <ol>
            <li>
              {t(
                'Use the Licensed Product for any purpose other than personal and non-commercial purposes;',
              )}
            </li>
            <li>
              {t(
                'Use the Licensed Product for any illegal or unlawful purpose;',
              )}
            </li>
            <li>
              {t(
                'Gather factual content or any other portion of the Licensed product by any automated means, including but not limited to database scraping or screen scraping; or',
              )}
            </li>
            <li>
              {t(
                'Reverse engineer, decompile, or disassemble Software, except and only to the extent that such activity is expressly permitted by applicable law notwithstanding the limitation.',
              )}
            </li>
          </ol>
        </p>
        <h3 className="user-agreement-sub-title">
          {t('Update and Maintenance')}
        </h3>
        <p>
          {t(
            'Syntegra KG and Aux Code OOD shall provide updates and maintenance on a monthly basis or as-needed basis.',
          )}
        </p>
        <h3 className="user-agreement-sub-title">{t('Support')}</h3>
        <p>
          {t(
            'Syntegra KG and Aux Code OOD has no obligation to Software support, or to continue providing or updating any of the Software.',
          )}
        </p>
        <h3 className="user-agreement-sub-title">General Provisions</h3>
        <strong>{t('Termination')}</strong>
        <p>
          {t(
            'In the event of termination, all licenses provided under this EULA shall immediately terminate, and you agree to discontinue accessing or attempting to access this Licensed product.',
          )}
        </p>
        <p>
          {t('Accordingly, this EULA may be:')}
          <ol>
            <li>
              {t(
                'Automatically terminated if the Licensee fails to comply with any of the terms and conditions under this EULA;',
              )}
            </li>
            <li>{t('Terminated by Syntegra KG and Aux Code OOD; or')}</li>
            <li>{t('Terminated by the Licensee.')}</li>
          </ol>
        </p>
        <p>
          {t(
            'Either Syntegra KG and Aux Code OOD or the Licensee may terminate this EULA immediately upon written notice to the other party, including but not limited to electronic mail.',
          )}
        </p>
        <strong>{t('Non-Transferability')}</strong>
        <p>
          {t(
            'The Licensee has the option to permanently transfer all rights under this Agreement, provided the recipient agrees to the terms of this EULA. Accordingly, this EULA is not assignable or transferable by the Licensee without the prior written consent of Syntegra KG and Aux Code OOD; and any attempt to do so shall be void.',
          )}
        </p>
        <strong>{t('Notice')}</strong>
        <p>
          {t(
            'Any notice, report, approval or consent required under this EULA shall be in writing and deemed to have been duly given if delivered by recorded delivery to the respective addresses of the parties.',
          )}
        </p>
        <strong>Integration</strong>
        <p>
          {t(
            'Both parties hereby agree that this EULA is the entire and exclusive statement and legal acknowledgement of the mutual understanding of the parties and supersedes and cancels any previous written and oral agreement and/or communication relating to the subject matter of this EULA.',
          )}
        </p>
        <strong>{t('Severability')}</strong>
        <p>
          {t(
            'No delay or failure to exercise, on the part of either party, any privilege, power or rights under this EULA shall operate as a waiver of any of the terms and provisions of this EULA. Accordingly, no single or partial exercise of any right under this Agreement shall preclude further exercise of any other right under this EULA. Suppose any of the outlined provisions of this EULA is deemed to be unenforceable or invalid in whole or in part by a court of competent jurisdiction. In that case, such provision shall be limited to the minimum extent necessary for this EULA to remain in full force and effect and enforceable. The remaining provisions of this Agreement shall not be rendered unenforceable or invalid. They shall continue to be enforceable and valid in isolation of the unenforceable and invalid provisions of this EULA.',
          )}
        </p>
        <strong>{t('Warranty and Disclaimer')}</strong>
        <p>
          {t(
            'Syntegra KG and Aux Code OOD, and author of this Software, expressly disclaim any warranty for the Qck.app. The Licensed Product and all applicable documentation is provided as-is, without warranty of any kind, whether express or implied, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. Accordingly, the Licensee accepts any risk arising out of the use or performance of the Software.',
          )}
        </p>
        <strong>{t('Limited Liability')}</strong>
        <p>
          {t(
            'The Licensee agrees that the Syntegra KG and Aux Code OOD shall not be liable to Licensee, or any other related person or entity claiming any loss of profits, income, savings, or any other consequential, incidental, special, punitive, direct or indirect damage, whether arising in contract, tort, warranty, or otherwise. Even if Syntegra KG and Aux Code OOD has been advised of the possibility of such damages. These limitations shall necessarily apply regardless of the primary purpose of any limited remedy. Under no circumstances shall Syntegra KG and Aux Code OOD aggregate liability to the Licensee, or any other person or entity claiming through the Licensee, exceed the actual monetary amount paid by the Licensee to Syntegra KreG and Aux Code OOD for the Software.',
          )}
        </p>
        <strong>{t('Indemnification')}</strong>
        <p>
          {t(
            'You hereby agree to indemnify and hold Syntegra KG and Aux Code OOD harmless from and against all liabilities, damages, losses or expenses, including but not limited to reasonable attorney or other professional fees in any claim, demand, action or proceeding initiated by any third-party against Syntegra KG and Aux Code OOD, arising from any of your acts, including without limitation, violating this EULA or any other agreement or any applicable law.',
          )}
        </p>
        <strong>{t('Entire Agreement')}</strong>
        <p>
          {t(
            'This Agreement rightly constitutes the entire understanding between the Syntegra KG and Aux Code OOD and the Licensee and all parties involved. It supersedes all prior agreements of the parties, whether written or oral, express or implied, statement, condition, or a representation or warranty.',
          )}
        </p>
        <strong>{t('Governing Law and Jurisdiction')}</strong>
        <p>
          {t(
            'It is agreed, that all legal actions will be treated under Austrian law before an Austrian Court.',
          )}
        </p>
        <Button
          onClick={() => navigate(-1)}
          style={{
            borderRadius: 9,
            backgroundColor: 'black',
            padding: '18px 36px',
            fontSize: '18px',
            maxWidth: '335px',
            width: '100%',
            margin: '0 auto',
            marginBottom: '5rem',
          }}
          variant="contained"
        >
          {t('Back')}
        </Button>
      </div>
    </>
  );
}
