import * as React from 'react';
import style from './WorkingQuestionInput.module.css';
import { ROLE_PERMISSION_MAP } from '../../constants';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
// import 'primereact/resources/themes/saga-blue/theme.css';  // theme
import 'primereact/resources/primereact.min.css';           // core css
import { Button } from 'primereact/button'

interface WorkingQuestionInputProps{
  workingQuestionIndex: number
  workingQuestion: WorkingQuestion
  saveChanges: (index: number, newText: string) => void
  changeType: (index: number, newType: WorkingQuestionType) => void
  changeWorkingQuestionOwnership: (index: number, take: boolean) => void
  remove: (index: number) => void
  currentUserRole: Role
  isCurrentUserCreator: boolean
  hasMeetingEnded: boolean
  changeBacklogStatus: (index: number, newState: boolean) => void
}

const WorkingQuestionInput = React.memo(
   ({ workingQuestionIndex, workingQuestion, saveChanges, changeType, remove, hasMeetingEnded, currentUserRole, isCurrentUserCreator, changeWorkingQuestionOwnership,changeBacklogStatus}: WorkingQuestionInputProps) => {
  
  const { type } = workingQuestion;
  const userEmail = sessionStorage.getItem('qckEmail');
  const [ currentText, setCurrentText ] = React.useState(workingQuestion.text);
  const [ saveChangesTimeout, setSaveChangesTimeout ] = React.useState<number | undefined>();
  const [ canUserEditField, setCanUserEditField ] = React.useState<boolean>();
  const [ backlogSymbol, setBacklogState ] = React.useState<string>();
  const handleTypeChange = (newType: WorkingQuestionType) => changeType(workingQuestionIndex, newType); 

  const handleTextWrite = (newText: string) => {
    setCurrentText(newText);
    if(saveChangesTimeout){
      window.clearTimeout(saveChangesTimeout);
      setSaveChangesTimeout(undefined);
    }
    setSaveChangesTimeout(window.setTimeout(() => saveChanges(workingQuestionIndex, newText), 100));
  }

  React.useEffect(() => {
    if(workingQuestion.text !== currentText){
      setCurrentText(workingQuestion.text);
    }
  }, [workingQuestion])

  React.useEffect(() => {
    const rolePermissions = ROLE_PERMISSION_MAP[currentUserRole];

    const hasOwnership = !workingQuestion.owner || workingQuestion.owner === userEmail;
    const hasPermissions = 
       rolePermissions.includes('agenda.edit.all') ||
       (rolePermissions.includes('agenda.edit.ours') && isCurrentUserCreator)
    setCanUserEditField(
      hasOwnership && hasPermissions
    );
  }, [workingQuestion]);

  return (
    <>
      <div
        className={style.wrapper}
      >
        {/*<Button className={style.deleteButton} onClick={() => remove(workingQuestionIndex)}>
          X
        </Button>*/}
        {!canUserEditField && <Tooltip content={
          hasMeetingEnded ? 'You can\'t edit the agenda after the meeting is over.'
          : 'You can not edit this agenda point right now.'
        } target={'.input-' + workingQuestionIndex} mouseTrack/>}
        <div
          className={`${style.input} input-${workingQuestionIndex}`}
        >
        { ROLE_PERMISSION_MAP[currentUserRole].includes('agenda.backlog') &&
          <Button 
            label={workingQuestion.isBacklogged ? '<' : '>'}
            onClick={() => changeBacklogStatus(workingQuestionIndex,!workingQuestion.isBacklogged)}
            style={workingQuestion.isBacklogged ? {
              backgroundColor: 'gray',
              background: 'gray',
              border: '1px solid gray',
              outline: 'gray'
            } : {}}
            tooltip={
              workingQuestion.isBacklogged ?
                'Make active' : 'Move to backlog'
            }
          ></Button>
        }
          <InputText
            placeholder={!hasMeetingEnded ? 'Enter your working question here' : '(Empty)'}
            //TODO
            onKeyDown={(event) => event.stopPropagation() }
            onChange={(e) => handleTextWrite(e.target.value)}
            onDrop={(e) => e.preventDefault()}
            value={currentText}
            style={{
              flexGrow: 1,
              margin: '0 2rem',
              lineHeight: .8,
              fontSize: 15,
              minWidth: '300px'
            }}
            disabled={hasMeetingEnded || !canUserEditField}
            autoComplete='off'
            onFocus={() => changeWorkingQuestionOwnership(workingQuestionIndex, true)}
            onBlur={() => changeWorkingQuestionOwnership(workingQuestionIndex, false)}
          />
        </div>
        {
          !hasMeetingEnded ?
            <Dropdown
              value={type}
              /*style={{
                height: 'min-content',
                fontSize: 15,
                lineHeight: 1.2
              }}*/
              onChange={(e) => handleTypeChange(e.value)}
              options={[
                {label: 'Understanding information', value: 'information'},
                {label: 'Developing Options', value: 'options'},
                {label: 'Decisions', value: 'decision'}
              ]}
              style={{
                width: '200px',
              }}
              placeholder='Purpose'
              className={`w-full md:w-14rem ${style.dropdown}`}
              //options={['Understanding information', 'Developing options', 'Decisions and actions']}
            />
            :
            <span
              style={{
                fontSize: 15
              }}
            >
              {type}
            </span>
          }
      </div>
    </>
  )
  }
)
export default WorkingQuestionInput;
