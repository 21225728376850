import '../styles/voteSuccess.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Navbar from '../Navbar';
import QuestionnaireSettings from '../components/QuestionnaireSettings';
import * as React from 'react';

export default function VoteSuccess() {
  const { t } = useTranslation();
  const { qnrIdParam } = useParams();
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean | undefined>();
  const navigate = useNavigate();

  const authenticate = () => {
    axios
    .get('/api/get-account-name')
    .then(({data}) => {
      setIsLoggedIn(!!data.message);
    })
  }

  React.useEffect(authenticate, []);

  return (
    <>
      <Navbar />
      <div className="thx-wrapper">
        <h1 id="thx-h1">Thank you!</h1>
        <p id="thx-msg">{t('Your feedback has been recorded')}</p>
        
        <QuestionnaireSettings
          customId = {qnrIdParam || ''}
        />

        <Button
          onClick={() => navigate(
            isLoggedIn !== undefined ? 
              isLoggedIn
              ? `/results/p/${qnrIdParam}`
              : `/results/${qnrIdParam}`
            : ''
          )}
          style={{
            borderRadius: 9,
            backgroundColor: 'black',
            padding: '18px 36px',
            fontSize: '18px',
            maxWidth: '335px',
            width: '100%',
            margin: '0 auto',
            marginBottom: '5rem',
            color: 'white',
            display: 'block',
          }}
        >
          View Results
        </Button>
      </div>
    </>
  );
}
