import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Create from './pages/Create';
import Home from './pages/Home/Home';
import Vote from './pages/Vote';
import Results from './pages/Results';
import VoteSuccess from './pages/VoteSuccess';
import './index.css';
import NotFound from './pages/NotFound';
import ResultsPublic from './pages/ResultsPublic';
import UserAgreement from './pages/UserAgreement';
import ContactUs from './pages/ContactUs';
import ChooseQnrType from './pages/ChooseType/ChooseType';
import Login from './pages/Login/Login';
import EnterAccount from './pages/EnterAccount';
import AboutMyAccount from './pages/MyAccount/MyAccount';
import LearnMore from './pages/LearnMore';
import CCC from './pages/CCC';
import TeamsCreateQnrMain from './pages/TeamsCreateQnrMain';
import TeamsCreateQnrOther from './pages/TeamsCreateQnrOther';
import TeamsConfig from './pages/TeamsConfig';
import TeamsLoading from './pages/TeamsLoading';
import TeamsHowTo from './pages/TeamsHowTo';
import TeamsQnrResults from './pages/TeamsQnrResults';
import TeamsQnrAnswer from './pages/TeamsQnrAnswer';
import TeamsUser from './pages/TeamsUser'
import TeamsAuth from './pages/TeamsAuth';
import TeamsAuthCallback from './pages/TeamsAuthCallback';
import DisableNotifications from './pages/DisableNotifications';
import Dashboard from './pages/Dashboard';
import * as React from 'react';
import NewRegularMeeting from './pages/NewRegularMeeting';
import CustomQuestion from './pages/CustomQuestion';
import {ProtectedRoutes} from './components/ProtectedRoutes';
import OwnerMeetingRouter from './pages/Meeting/OwnerMeetingRouter';
import UserMeetingRouter from './pages/Meeting/Users/UserMeetingRouter';

import { PrimeReactProvider } from 'primereact/api';

export default function App() {
  const value = {
    ripple: true
};
  return (
    <PrimeReactProvider value={value}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route index element={<Home />} />

          <Route path = '/hawlefco' element = {<CustomQuestion />}/>
          <Route path = "/user-agreement" element={<UserAgreement />} />
          <Route path = "/learn-more" element={<LearnMore />} />
          <Route path = "/ccc" element={<CCC />} />
          <Route path = "/contact" element={<ContactUs />} />
          <Route path = "/vote-success/:qnrIdParam" element={<VoteSuccess />} />
          <Route path = "/login" element={<Login />} />
          <Route path = '/disable-notifications/:userCode' element = { <DisableNotifications/> } />
          <Route path = "/enter-account/:emailParam/:keyParam" element={<EnterAccount />} />
          <Route path = "/results/:qnrIdParam" element={<ResultsPublic />} />

          <Route element={<ProtectedRoutes />} >
            <Route path = "/results/p/:qnrId" element={<Results />} />
            <Route path = "/create/:qnrId" element={<Create />} />
            <Route path = "/choose-qnr-type" element={<ChooseQnrType />} />
            <Route path = "/about-my-account/:email" element={<AboutMyAccount />} />
            <Route path = '/authenticate' element = { <TeamsAuth /> } />
            <Route path = '/callback' element = { <TeamsAuthCallback/> } />
            <Route path = '/dashboard/*' element = { <Dashboard /> } />
            <Route path = '/new-regular-meeting/' element = {<NewRegularMeeting />} />
            <Route path = "/:id/*" element={<OwnerMeetingRouter />} />
            <Route path = "/p/:id/*" element={<UserMeetingRouter />} />
          </Route>

          <Route path = "/ms-teams/pages/config" element = {< TeamsConfig />} />
          <Route path = "/ms-teams/pages/start-qnr" element = {< TeamsCreateQnrMain/>}/>
          <Route path = "/ms-teams/pages/start-qnr-other" element = {< TeamsCreateQnrOther/>}/>
          <Route path = "/ms-teams/pages/loading" element = { <TeamsLoading/> } />
          <Route path = "/ms-teams/pages/how-to" element = { <TeamsHowTo/> } />
          <Route path = "/ms-teams/show-qnr/:customId/:keyStr" element = { <TeamsQnrResults />} />
          <Route path = "/ms-teams/show-qnr/:meetingId" element = { <TeamsQnrAnswer />}/>
          <Route path = '/ms-teams/pages/user' element = { <TeamsUser/>} />

          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </PrimeReactProvider>
  );
}
