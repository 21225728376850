import * as React from 'react';
import { useParams } from 'react-router-dom'; import style from './Agenda.module.css';
import WorkingQuestionInput from '../WorkingQuestionInput';
import axios, { AxiosResponse } from 'axios';
import { OrderList } from 'primereact/orderlist';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';  // theme
import WorkingQuestionsOrderList from '../CustomOrderList/WorkingQuestionsOrderList';
import {ROLE_PERMISSION_MAP} from '../../../constants';
import WorkingQuestionPopover from '../WorkingQuestionPopover';

export default function Agenda(props: {
  workingQuestions: WorkingQuestion[]
  setWorkingQuestions?: (workingQuestions: WorkingQuestion[]) => void
  dateTimeEnded: number | undefined
  userRole: Role
  includeBacklog?: boolean
}){
  const userEmail = sessionStorage.getItem('qckEmail');

  const [ workingQuestions, setWorkingQuestions ] = React.useState<WorkingQuestion[]>(props.workingQuestions);


  const [ changes, setChanges ] = React.useState<AgendaChange[]>([]);
  const [ lastUpdate, setLastUpdate ] = React.useState<number>(Date.now());

  const syncIntervalId = React.useRef<number>();
  const isRequestActive = React.useRef<boolean>(false);

  const sync = () => setChanges(curr => {
    if(isRequestActive.current) return curr

    axios
    .post('/api/meeting-agenda', { 
      changes: curr,
      lastUpdate,
      id
    })
    .then((response) => {
      if(response.status === 200){
        setChanges(curr_after => {
          if(curr_after.length <= 0){
            setWorkingQuestions([...response.data]);
            props.setWorkingQuestions && props.setWorkingQuestions([...response.data]);
            setLastUpdate(Date.now());
          }
          return curr_after;
        })
      }
      isRequestActive.current = false;
    })
    isRequestActive.current = true;
    return [];
  })

  React.useEffect(() => {
    syncIntervalId.current = window.setInterval(sync, 1000);

    return () => {
      setWorkingQuestions(curr => {
        props.setWorkingQuestions && props.setWorkingQuestions(curr);
        return curr;
      })
      window.clearInterval(syncIntervalId.current)
    };
  }, []);

  const addChange = (type: AgendaChangeType, props: any) => {
    setChanges(curr => [...curr, { type, props }]);
  }

  const { dateTimeEnded } = props;

  const { id } = useParams();
  
  const handleLongPoolResponse = async (response: AxiosResponse) => {
    console.log(response.status);
    if(response.status === 204){
      return;
    }
    if(response.status === 200){
      const workingQuestions = response.data as WorkingQuestion[];
      setWorkingQuestions(workingQuestions);
    }
  }

  const changeBacklogStatus = (index: number, newState: boolean) => {
    setWorkingQuestions(curr => {
      const _new = [ ...curr ];
      _new[index].isBacklogged = newState;
      addChange('backlog', { index, newState });
      return _new;
    })
  }

  const changeWorkingQuestionType = (index: number, type: WorkingQuestionType) => {
    setWorkingQuestions(curr => {
      const _new = [...curr];
      _new[index].type = type;
      addChange('texttype', { index, type });
      return _new;
    })
  }

  const changeWorkingQuestionOwnership = (index: number, take: boolean) => {
    //take meaning current user either tries to take ownership or tries to lose it
    const currentUserEmail = sessionStorage.getItem('qckEmail') || "";
    setWorkingQuestions(curr => {
      const _new = [...curr];
      _new[index].owner = take ? currentUserEmail : undefined;
      addChange('owner', { index, newOwner: take ? currentUserEmail : undefined });
      return _new;
    })
  }

  const setWorkingQuestionText = (index: number, newText: string) => {
    setWorkingQuestions(curr => {
      const _new = [...curr];
      _new[index].text = newText;
      addChange('texttype', { index, text: newText});
      return _new;
    })
  }

  //NOT functional rn
  const removeWorkingQuestion = (index: number) => {
    setWorkingQuestions((curr) => {
      const _new = [...curr];
      _new.splice(index, 1);
      axios.delete('/api/meeting-agenda', { params: { id, index }}).then(handleLongPoolResponse);
      return _new;
    })
  }

  const addWorkingQuestion = (text = '', type: WorkingQuestionType = '' ) => {
    setWorkingQuestions((curr) => [...curr, {text, type, creator: userEmail || ""}] )
    addChange('add', { text, type });
  }

  //TODO
  const handleWorkingQuestionReorder = (newWorkingQuestions: WorkingQuestion[]) => {
    setWorkingQuestions(newWorkingQuestions);
    return;
  }

  const workingQuestionInputTemplate = (workingQuestion: WorkingQuestion): React.ReactNode => {

    const index = workingQuestions.indexOf(workingQuestion);
    return (
      <WorkingQuestionInput
        key={index}
        workingQuestionIndex={index}
        saveChanges={setWorkingQuestionText}
        changeType={changeWorkingQuestionType}
        workingQuestion={workingQuestion}
        hasMeetingEnded={!!dateTimeEnded}
        remove={removeWorkingQuestion}
        isCurrentUserCreator={workingQuestion.creator === userEmail}
        currentUserRole={props.userRole}
        changeWorkingQuestionOwnership={changeWorkingQuestionOwnership}
        changeBacklogStatus={changeBacklogStatus}
      />
    )
  }

  return (
    <>
      <section
        className={style.wqSection}
      >
        <WorkingQuestionsOrderList
          workingQuestions={workingQuestions.filter((wq) => !wq.isBacklogged)}
          workingQuestionInputTemplate={workingQuestionInputTemplate}
          setWorkingQuestions={setWorkingQuestions}
          addChange={addChange}
          hasReorderPermissions={ROLE_PERMISSION_MAP[props.userRole].includes('agenda.reorder')}
        />
        {/*workingQuestions.length > 0 ? workingQuestions.map((wq, index) => (
          <WorkingQuestionInput
            key={index}
            workingQuestionIndex={index}
            saveChanges={setWorkingQuestionText}
            changeType={changeWorkingQuestionType}
            workingQuestion={wq}
            hasMeetingEnded={!!dateTimeEnded}
            remove={removeWorkingQuestion}
            isCurrentUserCreator={wq.creator === userEmail}
            currentUserRole={props.userRole}
            changeWorkingQuestionOwnership={changeWorkingQuestionOwnership}
          />
        )) : <p
          style={{
            margin: 0,
            fontSize: '2rem',
            textAlign: 'center'
          }}
          >You have no working questions! Click on the + to create new ones!</p> */}
      </section>
      <div
        className={style.newButtonWrapper}
        style={{
          display: dateTimeEnded ? 'none' : 'flex'
        }}
      >
        <Button
          style={{
            fontSize: '1.5rem',
            margin: '1rem 1rem 0 auto'
          }}
          onClick={() => addWorkingQuestion()}
        >+</Button>
      </div>
      {/*TODO backlog*/}
      {props.includeBacklog && workingQuestions.filter((wq) => wq.isBacklogged).length > 0 && <>
        <h1 className={style.backlogHeading}>
        <span style={{marginRight: '1rem'}}>Backlog</span> <WorkingQuestionPopover popoverText={'(i)'} contentText={'Working questions in the backlog won\'t be discussed in the meeting. '} style={{ fontSize: '3rem'}}/>

        </h1>
        {/*map wq inputs*/}
        {workingQuestions.filter((wq) => wq.isBacklogged).map((wq, index) => (
          <WorkingQuestionInput
            key={index}
            workingQuestionIndex={index}
            saveChanges={setWorkingQuestionText}
            changeType={changeWorkingQuestionType}
            workingQuestion={wq}
            hasMeetingEnded={!!dateTimeEnded}
            remove={removeWorkingQuestion}
            isCurrentUserCreator={wq.creator === userEmail}
            currentUserRole={props.userRole}
            changeWorkingQuestionOwnership={changeWorkingQuestionOwnership}
            changeBacklogStatus={changeBacklogStatus}
          />
        ))}
      </>}
    </>
  )
}
