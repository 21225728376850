import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import '../styles/results.css';
import { useTranslation } from 'react-i18next';
import DotsLineResults from '../components/DotsLineResults';
import { Button } from '@mui/material';
import Navbar from '../Navbar';
import * as React from 'react';

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function Results() {
  const { t } = useTranslation();

  //Sets default questaionnare data
  const defaultData = {
    customId: '9999',
    keyStr: 'xxxxxxxxx',
  };
  const [allVotes, updateVotes] = useState<Questionnaire>(defaultData);

  //To add loading visualisation
  const [loading, setLoading] = useState(true);

  //Get qnr and string from the URL to access results
  const { qnrIdParam } = useParams();

  //Axios request to server
  const getVotes = async () => {
    await axios
      .get(baseUrl + 'api/get-results-public', {
        params: {
          customId: qnrIdParam,
        },
      })
      .then((response) => {
        updateVotes(response.data[0]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        alert('There was an error retrieveing data ');
      });
  };

  useEffect(() => {
    getVotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, setLoading]);

  return (
    <>
      <Navbar />

      {loading ? (
        <h2 id="results-h1">Loading...</h2>
      ) : (
        <>
          {allVotes.shared ? (
            <>
              <h1
                id="results-h1"
                style = {{
                  whiteSpace:   'normal',
                  width:        '80%',
                  overflowWrap: 'break-word'
                }}
              >
                {allVotes.qnrName || t('Results')}
              </h1>
              <div className="detailed-vote-wrapper">
                <span className="rating-label">
                  {t('Voters')}:{' '}
                  {(allVotes.votes && allVotes.votes.length) || 0}
                </span>
              </div>
              {allVotes.votes && allVotes.votes.length && (
                <DotsLineResults name={allVotes.qnrName || t('Results')} type={allVotes.type} votes={allVotes.votes} />
              )}
            </>
          ) : (
            <>
              <h1 id="results-h1">{allVotes.qnrName || ''}</h1>
              <h2 id="results-h1">
                {t('Results have not been released by the owner yet.')}
              </h2>
              <div className="rating-label">
                <Button
                  onClick={() => window.location.reload()}
                  style={{
                    borderRadius: 9,
                    backgroundColor: 'black',
                    padding: '18px 36px',
                    fontSize: '18px',
                    maxWidth: '335px',
                    width: '100%',
                    margin: '0 auto',
                    marginBottom: '5rem',
                  }}
                  variant="contained"
                >
                  {t('Refresh')}
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
