import '../../styles/RegularMeetings.css'
import RegularMeetingOverview from '../../components/RegularMeetingOverview'
import global from '../../types/global'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import axios from 'axios';
import Dots from '../../components/Dots'

export default function RegularMeetings(){

  const [regularMeetings, setRegularMeetings] = React.useState<RegularMeetingView[]>();
  const [loading, setLoading] = React.useState(true);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();

  React.useEffect(() => {

    const url = `${baseUrl}api/get-regular-meetings`

    axios
    .get(url)
    .then((result) => {
      setLoading(false);
      return setRegularMeetings(result.data);
    }).catch((reason) => {
      localStorage.setItem('afterLogInLocation', window.location.href);
      window.location.href = '/login'
    })
  }, [])

  const mainStyle : React.CSSProperties = {
    ...(!(regularMeetings && regularMeetings.length > 0) && {
      margin: 'auto',
      flexGrow: '1'
    } || {
      margin: '0',
      justifyContent: 'flex-start'
    })
  }

  return (
    <main
      style = {mainStyle}
    >
      {
        loading ?
          <div style={{fontSize: '5rem'}}>{t('Loading')}<Dots fontSize='5rem'/></div>
        :
          <>
            
            {
            (regularMeetings  && regularMeetings.length > 0) ? (
            <>
              <span
                className = 'heading-h1'
              >
                {t("Your Regular Meetings")}
              </span>
              {regularMeetings?.map((item, index) => (
                <RegularMeetingOverview _key = {index} key = {index} regularMeeting={item}/>
              ))}

              <div
                id = 'new-btn-cnt'
              >
                <button
                  onClick={() => window.location.href = '/new-regular-meeting'}
                >
                  {t('New')} +
                </button>
              </div>
            </>
            ):
            <span
              className='no-regular-meetings'
            >
              {t('You have no regular meetings!')}
              <br />
              {t('You can start one')}
              {' '}
              <a
                href="/new-regular-meeting"
              >
                {t("here")}
              </a>
              .
            </span>
            }  
          </>
      }
    </main>
  )
}
