import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { setupUserTheme } from '../functions/getUserTheme'
import TeamsNavbar from '../components/TeamsNavbar'
import * as microsoftTeams from '@microsoft/teams-js'
import axios from 'axios'
import '../styles/TeamsUser.css'
import TeamsVerifyView from '../components/TeamsVerifyView'
import { global } from '../../src/types/global'

export default function TeamsUser(){

  const { t } = useTranslation()

  const baseUrl = process.env.REACT_APP_BASE_URL

  const [theme, setTheme] = React.useState<TeamsColorTheme>('default')

  const checkForQnr = () => {
    microsoftTeams.app.initialize().then(
      () => microsoftTeams.app.getContext().then(context => {
        
        if(!context.meeting){
          window.location.href = ''
        }

        const meetingId = context.meeting?.id;
        axios
        .get(`${baseUrl}api/teams-get-qnr-id`, {
          params: {
            meetingId: meetingId
          }
        })
        .then(response => {
          const data = response.data
          if(data.qnrId){
            const pathname = `/ms-teams/pages/loading`
            localStorage.removeItem('last-page')
            window.location.href = pathname
          }
        })

    }))
  }

  useEffect( () => {
    setupUserTheme(setTheme)
    setInterval(checkForQnr, 5000)
  }, [])
  
  return (
    <div id = 'main'>
      <TeamsVerifyView/>
      <TeamsNavbar theme={theme}/>

      <p
        className = {`info-p ${theme}`}
      >
        {t('When the organizer makes a questionnaire you will be able to answer it here')}
      </p>

    </div>
  )
}
