import * as React from 'react';
import { OrderList, OrderListChangeEvent } from 'primereact/orderlist';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';  // theme
import style from './WorkingQuestionsOrderList.module.css';
import { OrderOperation } from '../../../enums';
import reorder from '../../../functions/reorder-array';

/** The reason for the existance of this component that wraps OrderList from Prime
  * is that we need to know precisely which working questions were swapped, so that we can
  * perfom this swap on the server-state meeting object, without overriding other changes
  *
  */
export default function WorkingQuestionsOrderList(props: {
  workingQuestionInputTemplate: (item: any) => React.ReactNode
  workingQuestions: WorkingQuestion[]
  setWorkingQuestions: React.Dispatch<React.SetStateAction<WorkingQuestion[]>>
  addChange: (type: AgendaChangeType, props: any) => void
  hasReorderPermissions?: boolean
}){

  const [ focusedWqIndex, setFocusedWqIndex ] = React.useState<number | undefined>();


  const handleReorder = (index: number, op: OrderOperation) => {

    const [newWorkingQuestions, new_index] = reorder(props.workingQuestions, index, op)

      props.setWorkingQuestions(newWorkingQuestions);

      //Slight timeout is workaround, dont worry
      setTimeout(() => {
        setFocusedWqIndex(new_index)
        const element = document.getElementById(`wq-ignore-${new_index}`);
        if(op === OrderOperation.START || op === OrderOperation.END)
          element && element.scrollIntoView({ behavior: 'smooth' })
      }, 5);
      props.addChange('order', {
        index, op
      });
      
  }

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {

      //Code is bloody messy but not gonna bother, it works
      const target = event.target as HTMLElement;

      if(!target) return;

      let shouldIgnore = false;
      if(target.id.includes('wq-ignore')){
        shouldIgnore = true;
      }else{
        let parent: HTMLElement | null | undefined = target.parentElement;
        while(true){
          if(parent?.id.includes('wq-ignore')){
            shouldIgnore= true;
            break;
          }else{
            parent = parent?.parentElement;
            if(!parent) break;
          }
        }
      }
      if(!shouldIgnore){
        setFocusedWqIndex(undefined);
      }
    }
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, [])

  return (
    <>
    {props.workingQuestions.length > 0 ? props.workingQuestions.map((wq, index) => (
        <div
         key={index}
         className={`${style.wq} ${
           focusedWqIndex === index ? style.focused : ''
         }`}
         onClick={() => {
           setFocusedWqIndex(index);
         }}
         id={`wq-ignore-${index}`}
        >
          <div
            className={style.wqActionButtons}
          >
            {focusedWqIndex === index && props.hasReorderPermissions ?
              <>
                <Button
                  id='wq-op-button_1'
                  onClick={() => handleReorder(index, OrderOperation.UP)}
                >
                  ︿
                </Button>
                <Button
                  id='wq-op-button_2'
                  onClick={() => handleReorder(index, OrderOperation.START)}
                >
                  ︽
                </Button>
                <Button
                  id='wq-op-button_3'
                  onClick={() => handleReorder(index, OrderOperation.DOWN)}
                >
                  ﹀
                </Button>
                <Button
                  id='wq-op-button_4'
                  onClick={() => handleReorder(index, OrderOperation.END)}
                >
                  ︾
                </Button>
              </>
                :
              <></>
            }
          </div>
          {props.workingQuestionInputTemplate(wq)}
        </div>
    )) : 
      <p
        style={{
          margin: 0,
          fontSize: '2rem',
          textAlign: 'center'
        }}
      >You have no working questions! Click on the + to create new ones!</p>
    }
    </>
  )
}

