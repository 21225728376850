import * as React from 'react';
import style from './GivePopup.module.css';
import { MIN_QUESTION_ANSWER, MAX_QUESTION_ANSWER, TYPE_QUESTIONS_MAP, NUMBER_TYPE_TYPE_MAP, TYPE_HELPER_MAP} from '../../../../constants';
import {Button, Slider, inputAdornmentClasses} from '@mui/material';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {useMeeting} from '../../../../contexts/MeetingContext';

export default function GivePopup(props: {
  setShouldShowVotePopup: React.Dispatch<React.SetStateAction<boolean>>;
  type: MeetingType
}) {

  const { type } = props;

  const questions = TYPE_QUESTIONS_MAP[type];

  const { id } =  useParams();

  const [ answers, setAnswers ] = React.useState<number[]>(Array.from({length: questions.length}).fill(5) as number[]);
  const [ opinions, setOpinions ] = React.useState<string[]>(Array.from({length: questions.length}).fill("") as string[]);

  const textFieldRef = React.useRef<HTMLTextAreaElement>(null);

  const marksArray = [{value: MIN_QUESTION_ANSWER, label: MIN_QUESTION_ANSWER + ''}, {value: MAX_QUESTION_ANSWER, label: MAX_QUESTION_ANSWER + ''}]

  const handleSliderChange = (event: Event) => {
    const slider = event.target as { value: number, name: string } | null;

    if(!slider) return;

    const index = parseInt(slider.name);
    setAnswers((curr) => {
      const _new = [...curr];
      _new[index] = slider.value;
      return _new;
    })
  }

  const handleOpinionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = event.target as HTMLTextAreaElement;

    if(!textarea) return;

    const text = textarea.value;
    const index = parseInt(textarea.name);
    setOpinions((curr) => {
      const _new = [...curr];
      _new[index] = text || '';
      return _new;
    })

    resize(textarea);
  }

  //resizes textarea so that all the text is visible on the screen at the same time
  const resize = (textArea: HTMLTextAreaElement) => {
    if(textArea){
      textArea.style.height = 'auto';
      textArea.style.height = (textArea.scrollHeight) + 'px';
    }
  }

  const submit = () => {
    const _opinions: string[] = opinions.map((op) => op || 'No answer');
    axios
    .patch('/api/meeting-feedback', { answers, opinions: _opinions, id })
    .then(() => window.location.reload());
  }

  //Handles resize of all text areas every time user resizes screen
  React.useEffect(() => {
    const resizeAll = () => {
      const inputTextAreas = Array.from(document.querySelectorAll('[title="opinion-input"]'));
      inputTextAreas.forEach((textarea) => resize(textarea as HTMLTextAreaElement));
    }
    window.addEventListener('resize', resizeAll);
    return () => window.removeEventListener('resize', resizeAll);
  }, [])

  return (
    <div
      className={style.container}
    >
      <h1 className={style.meetingType}>
        {NUMBER_TYPE_TYPE_MAP[type]}
      </h1>
      <h1 className={style.heading1}>
        Your feedback will be appreciated! 
      </h1>
      <h2 className={style.heading2}>
        Answer with a number between {MIN_QUESTION_ANSWER} and {MAX_QUESTION_ANSWER}, depending on how much you agree with the statement
      </h2>
      
      <section className={style.sliderContainer}>
        {questions.map((q, index) => (
          <div className={style.questionContainer} key={index}>
            <p className={style.question}>
              {q}
            </p>
            <Slider
              onChange={handleSliderChange}
              name={index + ''}
              value={answers[index]}
              step={1}
              min={MIN_QUESTION_ANSWER}
              max={MAX_QUESTION_ANSWER}
              marks={[...marksArray, {value: answers[index], label: answers[index]}]}
              className={style.slider}
            />
            <textarea
              ref={textFieldRef}
              className={style.text}
              name={index + ''}
              value={opinions[index]}
              onChange={handleOpinionChange}
              placeholder={TYPE_HELPER_MAP[type][index]}
              title='opinion-input'
            >
            </textarea>
          </div>
        ))}
      </section>

      <div className={style.submitButtonContainer}>
        <Button
          variant='contained'
          style={{
            fontSize: '1.2rem',
            paddingLeft: '5rem',
            paddingRight: '5rem',
          }}
          onClick={submit}
        >Submit</Button>
        <Button
          variant='contained'
          style={{
            fontSize: '1.2rem',
            paddingLeft: '5rem',
            paddingRight: '5rem',
          }}
          onClick={() => props.setShouldShowVotePopup(false)}
        >Back</Button>
      </div>

    </div>
  )
}

