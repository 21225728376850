import '../styles/dotsLineResults.css';
import ResultsAccordion from '../components/ResultsAccordion';
import { useEffect, useState } from 'react';
import dynamicSort from '../functions/dynamicSort';
import axios from 'axios';
import { MAX_QUESTION_ANSWER, MIN_QUESTION_ANSWER, colorPallete } from '../constants';
import * as React from 'react';
import {
  usePDF,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import PdfResults from '../components/PdfResults';
import printJS from 'print-js';

export default function DotsLineResults(props: {
  votes: Array<Vote>;
  type?: number;
  name: string;
}) {
  const [votes, setVotes] = useState<Vote[]>(props.votes);
  const resultsLineRef = React.useRef<HTMLDivElement>(null);

  const type = props.type|| 1;
  const baseURL = process.env.REACT_APP_BASE_URL;

  const LEFT_COEF = 100 / ((MAX_QUESTION_ANSWER - MIN_QUESTION_ANSWER));
  const RESULT_DOT_SIZE = 25; //Rendered in pixels

  const [questionSet, setQuestionSet] = useState<Question[]>([]);

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}api/get-qnr-sett`, {
          params: { type: type },
        })
        .then((response) => {
          const responseArray = response.data.message;
          if (responseArray.length !== undefined) {
            setQuestionSet(responseArray);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const assignInitialsAndColors = () => {
    setVotes(
      votes.map((vote: Vote, i: number) => {
        const initials = vote.username?.match(/^\p{L}|\b(\p{L})/gu)?.join('.');
        if (initials) {
          return { ...vote, initials: initials, color: colorPallete[i] };
        }
        return vote;
      }),
    );
  };

  useEffect(() => {
    assignInitialsAndColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dotOffset: number[] = [];
  const prevValue: number[] = [];

  questionSet.forEach(() => {
    dotOffset.push(-9);
    prevValue.push(-1);
  });

  const lang = localStorage.getItem('language') || 'de';

  const [instance, updateInstance] = usePDF({
    document: (
      <PdfResults
        votes={votes}
        name={props.name}
        lang={lang}
        questionSet={questionSet}
        type={type}
      />
    ),
  });

  const [times, setTimes] = useState(0);

  const printPdf = () => {
    if (instance.url) {
      updateInstance(
        <PdfResults
          votes={votes}
          name={props.name}
          lang={lang}
          questionSet={questionSet}
          type={type || 5}
        />,
      );
      if (times > 0) {
        printJS({ printable: instance.url, type: 'pdf' });
      }
      setTimes(times+1)
    }
  };

  return (
    <div className="detailed-vote-wrapper">
      {questionSet.map((question, questionIndex) => (
        <div key={questionIndex}>
          <p className="rating-label">
            {lang === 'en' ? question.name : question.nameDe}
          </p>
          <div
            id="results-line"
            ref={resultsLineRef}
          >
            {votes.sort(dynamicSort(0)).map((vote: Vote, index: number) => {
              vote.questions[questionIndex].value === prevValue[questionIndex]
                ? (dotOffset[questionIndex] += 7)
                : (dotOffset[questionIndex] = -9);
              prevValue[questionIndex] = vote.questions[questionIndex].value;

              if (vote.questions[questionIndex].value < 0) {
                return '';
              }

              //Calculates the left offset of a result dot. Adjusts if its the last dot have its end, not start, at the end of the line
              const leftOffsetCoef = (vote.questions[questionIndex].value * LEFT_COEF) / 100;
              const resultsLineWidth = resultsLineRef.current?.offsetWidth || 0;
              let leftOffsetPixel = resultsLineWidth * leftOffsetCoef;

              if(leftOffsetCoef === 1) leftOffsetPixel -= RESULT_DOT_SIZE;

              return (
                <div
                  className="result-dots"
                  style={{
                    backgroundColor: vote.color === undefined ? '#DADADA' : vote.color,
                    bottom: dotOffset[questionIndex] + 'px',
                    left: leftOffsetPixel + 'px'
                  }}
                  key={index}
                >
                  {vote.initials}
                </div>
              );
            
            })}
          </div>
          <ResultsAccordion
            votes={votes}
            keyValue="value"
            keyMsg="text"
            index={questionIndex}
          />
        </div>
      ))}
      <br />
      {!instance.loading && (
        <button
          style={{
            fontSize: '20px',
            backgroundColor: 'black',
            borderRadius: '7px',
            padding: '6px',
            margin: '20px',
            textDecoration: 'none',
            color: 'white',
            fontFamily: 'Figtree',
            fontWeight: '900',
            border: 'none',
            cursor: 'pointer',
            height: '35px',
          }}
          className="downloadButton"
          onClick={printPdf}
        >
          {times === 1 ? 'Proceed to printing results as a PDF document?' : 'Print as PDF'}
        </button>
      )}

      <PDFDownloadLink
        className="downloadButton"
        style={{
          fontSize: '20px',
          backgroundColor: 'black',
          color: 'white',
          borderRadius: '7px',
          padding: '6px',
          margin: '20px',
          textDecoration: 'none',
        }}
        document={
          <PdfResults
            votes={votes}
            name={props.name}
            lang={lang}
            questionSet={questionSet}
            type={type}
          />
        }
        fileName="qckApp.pdf"
      >
        {({ loading }) => {
          return loading ? (
            <a
              style={{
                color: 'white',
                fontFamily: 'Figtree',
                fontWeight: '900',
              }}
            >
              Loading document
            </a>
          ) : (
            <a
              style={{
                color: 'white',
                fontFamily: 'Figtree',
                fontWeight: '900',
              }}
            >
              Download as PDF
            </a>
          );
        }}
      </PDFDownloadLink>
    </div>
  );
}
