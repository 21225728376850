import * as React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import UserMeeting from './UserMeeting';
import Navbar from '../../../Navbar';
import UserAgreement from '../../../components/UserAgreementLink';
import style from './UserMeetingRouter.module.css';
import MeetingContext, {useUserMeeting} from '../../../contexts/UserMeetingContext';
import VotingPopup from '../../../components/Meeting/Voting/User/VotingPopup';
import ResultsPopup from '../../../components/Meeting/Voting/Results/ResultsPopup';

import Agenda from '../../../components/Meeting/Agenda/Agenda';

import axios from 'axios';
import Contributors from '../Contributors/Contributors';

export default function UserMeetingRouter() {

  const { id } = useParams();

  const [ meeting, setMeeting ] = React.useState<Meeting>();
  const [ userRole, setUserRole ] = React.useState<Role>('User');

  React.useEffect(() => {
    axios
    .get('/api/meeting', { params: { meetingId: id } })
    .then((result) => {
      const meeting: Meeting = result.data.meeting;

      setMeeting(meeting);
      //get the email of the logged in user and find them in the array of invitees to fetch thier role
      const userEmail = sessionStorage.getItem('qckEmail');
      meeting.invitees.forEach((invitee) => {
        if(invitee.email === userEmail) setUserRole(invitee.role);
      })
    })
  }, [] )


  return (
    <>
      {meeting ? <>
      <Navbar />
      <main className={style.main} >
        <section className={style.content}>
          <Routes>
            <Route path='/contribute' element={<Contributors workingQuestions={meeting.workingQuestions} dateTimeEnded={meeting.dateTimeEnded} userRole={userRole} hasStarted={!!meeting.dateTimeStarted}/>} />
            <Route path='*' element={<UserMeeting meeting={meeting} />} />
          </Routes>
        </section>
      </main>
      <UserAgreement />
      </>
      : <></>}
    </>
  )
}
