import { Button, duration, selectClasses } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './DurationButtonGroup.module.css';
import * as React from 'react';

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function ModalButtonGroup(props: {
  qnrId: string;
  duration?: number;
  setIsDurationChosen?: any;
}) {
  
  const duration = props.duration;
  const { t } = useTranslation();

  const minDuration = 15, maxDuration = 90, step = 15;
  const presetDurationButtonCount = maxDuration / minDuration;
  
  const [ currentDuration, setCurrentDuration ] = useState<number | undefined>(duration);
  const [ selectedButton, setSelectedButton ] =  useState<number | undefined>();
  const [ customDurationInputVisible, setCustomDurationInputVisible ] = useState(false);
  const [ showLoadingOverlay, setShowLoadingOverlay ] = useState(false);
  
  const customDurationInputRef = React.useRef<HTMLInputElement>(null);

  const requestDurationChange = async (newDuration: number) => {
    setShowLoadingOverlay(true);
    await axios.put(baseUrl + 'api/set-qnr-duration', {
      params: {
        customId: props.qnrId,
        duration: newDuration,
      },
    }).finally(() => {
      setShowLoadingOverlay(false)
      setCurrentDuration(newDuration);
    });
  };

  const isDurationOfPresets = () => {
   return currentDuration && currentDuration <= maxDuration && currentDuration >= minDuration && currentDuration % step === 0;
  }

  const onload = () => {
    if( !currentDuration ) { setSelectedButton(undefined); return; }

    if(isDurationOfPresets()){
      const buttonToSelect = (currentDuration - minDuration) / step;
      setSelectedButton(buttonToSelect);
      return;
    }
    
    setSelectedButton(presetDurationButtonCount);
  }

  const handleDurationSelect = () => {
    if( selectedButton === undefined ) return;
    
    if( selectedButton >= 0 && selectedButton < presetDurationButtonCount ) {
      setCustomDurationInputVisible(false);
      requestDurationChange(minDuration + selectedButton * step); return;
    }

  }

  const handleCustomDurationChange = () => {
    if(!customDurationInputVisible){
      onload()
      return;
    }
    customDurationInputRef.current && customDurationInputRef.current.focus();
  }

  const handleDurationChange = () => {
    props.setIsDurationChosen && props.setIsDurationChosen(!!currentDuration);
  }

  useEffect( onload, [] );
  useEffect( handleCustomDurationChange, [customDurationInputVisible] );
  useEffect( handleDurationSelect, [selectedButton] );

  const handleCustomDurationSubmit : React.FormEventHandler = (event) => {
    if( !customDurationInputRef.current ) return;

    event.preventDefault();
    const inputedDuration = parseInt(customDurationInputRef.current.value);
    if( typeof inputedDuration === 'number' && !isNaN(inputedDuration) && inputedDuration > 0 ){
      requestDurationChange(inputedDuration);
    }
    
    customDurationInputRef.current.value = '';
    setCustomDurationInputVisible(false);
  }
  
  const toggleShowCustomDurationInput = () => {
    setCustomDurationInputVisible(!customDurationInputVisible)
  }

  return (
    <>
      <div className={style.wrapper}>
        
        <div
          className={style.loadingOverlay}
          style={{ display: showLoadingOverlay ? 'block': 'none' }}
        ></div>
        {
          Array.from({length: presetDurationButtonCount}, (_dummy, index) =>
            <Button
              key = {index}
              onClick={() => setSelectedButton(index)}
              className={`${style.durationButton} ${selectedButton === index ? style.selected : ''}`}
            >
              {minDuration + index * step}
            </Button>
          )
        }
        <div className='customDurationContainer'>
          <Button
            onClick={() => {
              toggleShowCustomDurationInput();
              setSelectedButton(presetDurationButtonCount);
            }}
            variant="contained"
            className={`${style.durationButton} ${selectedButton === presetDurationButtonCount ? style.selected : ''}`}
          >
            {
              currentDuration && !isDurationOfPresets() && !customDurationInputVisible
               ? currentDuration
                : t(`CUSTOM DURATION`)
            }
          </Button>
          <form
            onSubmit={handleCustomDurationSubmit}
            className={style.customDurationForm}
            style={{ display: customDurationInputVisible ? 'flex' : 'none' }}
          >
            <input
              ref={customDurationInputRef}
              className={style.customDurationInput}
              name="duration-setter"
              type="text"
              maxLength={3}            
            ></input>
            <button className={style.customDurationSubmit} type="submit">
              {t('save')}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
